import { ComponentType, lazy } from 'preact/compat';
import { logger } from './log';

interface RetryOptions {
  maxRetryCount?: number;
  retryDelayMs?: number;
}

const DEFAULT_RETRY_OPTIONS: Required<RetryOptions> = {
  maxRetryCount: 15,
  retryDelayMs: 500,
};

const uriOrRelativePathRegex = /import\(["']([^)]+)['"]\)/;

const getRouteComponentUrl = (originalImport: () => Promise<any>): string | null => {
  try {
    const fnString = originalImport.toString();
    return fnString.match(uriOrRelativePathRegex)?.[1] || null;
  } catch (e) {
    logger.error('Error extracting component URL:', e);
    return null;
  }
};

const getRetryImportFunction = (originalImport: () => Promise<any>, retryCount: number): (() => Promise<any>) => {
  const importUrl = getRouteComponentUrl(originalImport);
  if (!importUrl || retryCount === 0) {
    return originalImport;
  }

  const importUrlWithVersionQuery = importUrl.includes('?')
    ? `${importUrl}&v=${retryCount}-${Math.random().toString(36).substring(2)}`
    : `${importUrl}?v=${retryCount}-${Math.random().toString(36).substring(2)}`;

  return () => import(/* @vite-ignore */ importUrlWithVersionQuery);
};

export function lazyWithRetry<T extends ComponentType<any>>(
  importFunction: () => Promise<{ default: T }>,
  options: RetryOptions = {}
): T {
  const { maxRetryCount, retryDelayMs } = {
    ...DEFAULT_RETRY_OPTIONS,
    ...options,
  };
  let retryCount = 0;

  const loadComponent = (): Promise<{ default: T }> =>
    new Promise((resolve, reject) => {
      function tryLoadComponent() {
        const retryImport = getRetryImportFunction(importFunction, retryCount);

        retryImport()
          .then((module) => {
            if (retryCount > 0) {
              logger.debug(
                `Component loaded successfully after ${retryCount} ${retryCount === 1 ? 'retry' : 'retries'}.`
              );
            }
            resolve(module);
          })
          .catch((error) => {
            retryCount += 1;
            if (retryCount <= maxRetryCount) {
              const delay = retryDelayMs * Math.pow(2, retryCount - 1); // Exponential backoff
              logger.warn(`Retry attempt ${retryCount} failed, retrying in ${delay}ms...`);
              setTimeout(() => tryLoadComponent(), delay);
            } else {
              logger.error('Failed to load component after maximum retries. Reloading the page...', error);
              reject(error);
              // TODO: Push an OpenTelemetry error event when this happens
            }
          });
      }

      tryLoadComponent();
    });

  return lazy(() => loadComponent());
}
