export enum EventType {
  CLICK = 'click',
  FOCUS = 'focus',
}

interface IEventSubscriber {
  id: string;
  type: EventType;
  selector?: string;
  callback: Function;
}

let subscribers: IEventSubscriber[] = [];

export function addSubscriber(subscriber: IEventSubscriber) {
  const existingSubscriber = subscribers.find((existing) => existing.id === subscriber.id);

  if (existingSubscriber) {
    throw new Error(`RPH: Subscriber with id ${subscriber.id} already exists`);
  }

  subscribers.push(subscriber);
}

export function removeSubscriber(id: string) {
  const existingSubscriber = subscribers.find((existing) => existing.id === id);

  if (!existingSubscriber) {
    return;
  }

  subscribers = subscribers.filter((existing) => existing.id !== id);
}

function invokeSubscribers(this: HTMLElement, evt: Event) {
  const target = evt.target as HTMLElement;

  for (const subscriber of subscribers) {
    if (subscriber.type !== evt.type) {
      continue;
    }

    if (!subscriber.selector) {
      continue;
    }

    const closest = target.closest(subscriber.selector);
    if (closest) {
      Object.defineProperty(evt, 'target', {
        writable: false,
        value: closest,
      });
      return subscriber.callback.call(this, evt);
    }
  }
}

document.addEventListener('click', invokeSubscribers, true);
document.addEventListener('focus', invokeSubscribers, true);
