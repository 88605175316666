import { h, Fragment } from 'preact';
import _isEmpty from 'lodash-es/isEmpty';
import { AppSchema, GlobalState } from '@/scripts/hooks/use-global-context';
import { useCallback, useEffect, useMemo, useState } from 'preact/hooks';
import { Trans, useTranslation } from 'preact-i18next';
import { useNear, useRoute } from '@/scripts/hooks';
import Input from '../../Common/Input/Input';
import Button from '../../Common/Button/Button';
import { LoginStep } from '@/scripts/Login';
import { logger } from '@/scripts/utils/log';
import useUserApi from '@/scripts/hooks/use-user-api';

export enum WalletTypes {
  Ethers = 'ethers',
  Near = 'near',
  Unknown = 'unknown',
}

export interface Wallet {
  type: WalletTypes;
  field_name: string;
  display_name: string;
  account_id: string;
  wallet_id: string;
  private_key?: string;
  seed_phrase?: string;
}

interface Wallets {
  ethers: EthersWallet[];
  near: NearWallet[];
}

export interface EthersWallet extends Wallet {
  type: WalletTypes.Ethers;
}

export interface NearWallet extends Wallet {
  type: WalletTypes.Near;
  custodial: boolean;
  private_key?: string;
  seed_phrase?: string;
}

interface ProfileWalletsProps {
  state: GlobalState;
}

export default function ProfileWallets({ state }: ProfileWalletsProps) {
  const { t } = useTranslation();
  const { navTo } = useRoute();
  const { app, use_modal } = state;
  const { selector } = useNear();
  const [wallets, setWallets] = useState<Wallets>({ ethers: [], near: [] });
  const [disconnectingWallet, setDisconnectingWallet] = useState(false);
  const { saveUserData } = useUserApi();

  // Total wallets is the sum of the lengths of every value in the wallets map
  const totalWallets = useMemo(() => {
    return Object.values(wallets)
      .map((w) => w.length)
      .reduce((acc, w) => (acc += w), 0);
  }, [wallets]);

  const connectNearWallet = useCallback(async () => {
    navTo('/account/login', 'nav', {
      login_step: LoginStep.CHOOSE_WALLET_PROVIDER,
      wallet_provider_scopes: ['near'],
    });
  }, [navTo]);

  const computeAndSetWallets = useCallback(async (userData: Record<string, unknown>, schema: AppSchema) => {
    const nearWallets: NearWallet[] = [];
    if (userData.near_implicit_account_id) {
      nearWallets.push({
        type: WalletTypes.Near,
        account_id: userData.near_implicit_account_id as string,
        private_key: userData.near_implicit_private_key as string,
        seed_phrase: userData.near_implicit_seed_phrase as string,
        field_name: 'near_implicit_account_id',
        display_name: schema.near_implicit_account_id.display_name,
        wallet_id: 'my-near-wallet',
        custodial: true,
      });
    }

    if (userData.near_named_account_id) {
      nearWallets.push({
        type: WalletTypes.Near,
        account_id: userData.near_named_account_id as string,
        private_key: userData.near_named_private_key as string,
        seed_phrase: userData.near_named_seed_phrase as string,
        field_name: 'near_named_account_id',
        display_name: schema.near_named_account_id.display_name,
        wallet_id: 'my-near-wallet',
        custodial: true,
      });
    }

    if (userData.near_personal_account_id) {
      nearWallets.push({
        type: WalletTypes.Near,
        account_id: userData.near_personal_account_id as string,
        field_name: 'near_personal_account_id',
        display_name: schema.near_personal_account_id.display_name,
        wallet_id: userData.near_personal_account_wallet_id as string,
        custodial: false,
      });
    }

    const ethersWallets: EthersWallet[] = [];
    if (userData.crypto_wallet_address) {
      ethersWallets.push({
        type: WalletTypes.Ethers,
        account_id: userData.crypto_wallet_address as string,
        display_name: schema.near_implicit_account_id.display_name,
        wallet_id: 'unknown',
        field_name: 'crypto_wallet_address',
      });
    }

    setWallets({
      ethers: ethersWallets,
      near: nearWallets,
    });
  }, []);

  useEffect(() => {
    if (!state.user.data || !app.schema) {
      return;
    }

    computeAndSetWallets(state.user.data, app.schema);
  }, [app.schema, state.user.data, computeAndSetWallets]);

  const handleDisconnectWallet = useCallback(
    async (wallet: EthersWallet | NearWallet) => {
      try {
        setDisconnectingWallet(true);
        switch (wallet.type) {
          case WalletTypes.Near: {
            const nearWallet = wallet as NearWallet;
            const response = await window.rownd.connectionAction({
              action_type: 'near.disconnect-account',
              params: {
                account_id: nearWallet.account_id,
              },
            });

            if (!_isEmpty(response.data)) {
              saveUserData(response.data);
            }

            const walletSelectorWallet = await selector?.wallet(nearWallet.wallet_id);
            await walletSelectorWallet?.signOut();
            break;
          }
          default: {
            return logger.error(`Unsupported wallet type: ${wallet.type}`);
          }
        }
      } finally {
        setDisconnectingWallet(false);
      }
    },
    [saveUserData, selector],
  );

  const connectOrCreate = useMemo(() => {
    return {
      connect: app.schema?.near_personal_account_id && !state.user.data.near_personal_account_id,
      create: app.schema?.near_named_account_id && !state.user.data.near_named_account_id,
    };
  }, [
    app.schema?.near_named_account_id,
    app.schema?.near_personal_account_id,
    state.user.data.near_named_account_id,
    state.user.data.near_personal_account_id,
  ]);

  const connectButton = useMemo(() => {
    return <Button label={t('connect a wallet')} type={'text'} handleOnClick={connectNearWallet} />;
  }, [connectNearWallet, t]);

  const createButton = useMemo(() => {
    return (
      <Button
        label={t('create a new wallet')}
        type={'text'}
        handleOnClick={() => {
          navTo('/account/near/createNamedAccount', 'NearWalletDetails');
        }}
      />
    );
  }, [navTo, t]);

  // Don't show anything if wallets are not enabled
  if (!(app.schema?.near_implicit_account_id || app.schema?.near_named_account_id)) {
    return null;
  }

  return (
    <div className="rph-account-fields__wallets">
      {use_modal && (
        <>
          <div className="rph-field-divider" />
          <div id={`rph-field-title__wallets`} className="rph-field-title">
            Wallets
          </div>
        </>
      )}

      {app.schema && use_modal && (
        <>
          {totalWallets > 0 ? (
            <>
              {wallets.near.map((wallet, index) => {
                const labelForPersonalWallet = `${wallet.display_name}${
                  wallet.wallet_id ? ` (${wallet.wallet_id})` : ''
                }`;
                const disconnectSupported =
                  wallet.type === 'near' &&
                  state.app.config?.capabilities?.connection_actions?.find((action) => {
                    return action.action_type === 'near.disconnect-account';
                  });
                return (
                  <div class="rph-field rph-field__wallet rph-field__wallet__near" key={index}>
                    <label className="rph-field-name">
                      {wallet.custodial ? wallet.display_name : labelForPersonalWallet}
                    </label>
                    <div class="rph-field-value">
                      <Input
                        id={`rph-input-${wallet.field_name}-${index}`}
                        name={wallet.field_name}
                        type="text"
                        value={wallet.account_id}
                        readOnly={true}
                        copyable={true}
                      />
                      {wallet.custodial ? (
                        <Button
                          customClass="rph-your-wallet"
                          label={t('Your wallet')}
                          type={'primary'}
                          handleOnClick={() => {
                            navTo('/account/walletDetails', void 0, {
                              use_modal: true,
                              type: WalletTypes.Near,
                              wallet,
                            });
                          }}
                        />
                      ) : (
                        <>
                          {disconnectSupported && (
                            <Button
                              customClass="rph-disconnect-wallet"
                              label={t('Disconnect')}
                              type={'tertiary'}
                              handleOnClick={() => handleDisconnectWallet(wallet)}
                              isLoading={disconnectingWallet}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
              {wallets.ethers.map((wallet, index) => {
                return (
                  <div class="rph-field rph-field__wallet rph-field__wallet__near" key={index}>
                    <label className="rph-field-name">{wallet.display_name}</label>
                    <div class="rph-field-value">
                      <Input
                        id={`rph-input-${wallet.field_name}-${index}`}
                        name={wallet.field_name}
                        type="text"
                        value={wallet.account_id}
                        readOnly={true}
                        copyable={true}
                      />
                    </div>
                  </div>
                );
              })}
              {Object.values(connectOrCreate).some((val) => val) && (
                <span className="rph-account-fields__wallets__connect-or-create">
                  {connectOrCreate.connect && connectOrCreate.create ? (
                    <Trans>
                      You may also {connectButton} or {createButton}.
                    </Trans>
                  ) : (
                    <Trans>You may also {connectOrCreate.connect ? connectButton : createButton}.</Trans>
                  )}
                </span>
              )}
            </>
          ) : (
            <div className="rph-account-fields__wallets__empty">
              <span>
                <Trans>
                  No wallets found. Create a new wallet or
                  <Button label={t('connect a wallet')} type={'text'} handleOnClick={connectNearWallet} />
                </Trans>
              </span>
              <Button
                customClass="rph-account-fields__wallets__create"
                type={'primary'}
                label={t('Create new wallet')}
                handleOnClick={() => {
                  navTo('/account/near/createNamedAccount', 'NearWalletDetails');
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}
