import { GlobalState } from '../hooks/use-global-context';
import { isTokenExpired } from './token';

export const primaryColor = (state: GlobalState) => {
  const { app, computed_color_mode } = state;
  const { config } = app;

  return computed_color_mode === 'dark'
    ? config?.hub?.customizations?.primary_color_dark_mode || '#c8aaff'
    : config?.hub?.customizations?.primary_color || config?.customizations?.primary_color || '#5b13df';
};

export const appIcon = (state: GlobalState) => {
  const { app, computed_color_mode } = state;

  return (
    (computed_color_mode === 'dark'
      ? app?.config?.customizations?.logo_dark_mode
      : app?.config?.customizations?.logo) || app.icon
  );
};

export const isAuthenticated = (state: GlobalState) => {
  return state.auth?.access_token && !isTokenExpired(state.auth.access_token);
};
