import { useCallback, useEffect } from 'preact/hooks';
import { useRoute } from './hooks';
import { ActionType, useGlobalContext } from './hooks/use-global-context';
import useSafeLocation from './hooks/use-window-location';
import { addSubscriber, EventType, removeSubscriber } from './dom-listeners';
import { VALID_ATTRIBUTE_SELECTORS } from './ExternalDomController';

const InitDomListeners = () => {
  const { navTo } = useRoute();
  const { state, dispatch } = useGlobalContext();
  const { auth } = state;
  const safeLocation = useSafeLocation();

  const handleSignInClick = useCallback(
    (evt: Event) => {
      evt.preventDefault();
      evt.stopPropagation();

      if (!auth.access_token) {
        navTo('/account/login', void 0, { use_modal: true });
        return;
      }

      const loggedInRoute =
        (evt.target as HTMLElement).getAttribute('data-rph-logged-in-url') ||
        (evt.target as HTMLElement).getAttribute('data-rownd-authenticated-redirect-url');
      if (auth.access_token && loggedInRoute) {
        return safeLocation.assign(loggedInRoute);
      }

      const linkHref = (evt.target as HTMLElement).getAttribute('href');
      if (linkHref) {
        return safeLocation.assign(linkHref);
      }

      navTo('/account/manage');
    },
    [auth.access_token, navTo, safeLocation]
  );

  const handleSignOutClick = useCallback(
    (evt: Event) => {
      evt.preventDefault();
      evt.stopPropagation();
      dispatch({ type: ActionType.SIGN_OUT });
    },
    [dispatch]
  );

  const handleTicketsOpen = useCallback(() => {
    window.rownd.squadUp.yourTickets();
  }, []);

  // External Account DOM listeners
  useEffect(() => {
    addSubscriber({
      id: 'sign-in-triggers',
      type: EventType.CLICK,
      selector: '[data-rph-login-btn]',
      callback: handleSignInClick,
    });

    addSubscriber({
      id: 'sign-in-triggers-rownd',
      type: EventType.CLICK,
      selector: `[${VALID_ATTRIBUTE_SELECTORS.SIGN_IN_BUTTON}]`, // [data-rownd-sign-in-trigger]
      callback: handleSignInClick,
    });

    addSubscriber({
      id: 'tickets-open-triggers',
      type: EventType.CLICK,
      selector: `[${VALID_ATTRIBUTE_SELECTORS.TICKETS_OPEN}]`,
      callback: handleTicketsOpen,
    });

    addSubscriber({
      id: 'sign-out-triggers',
      type: EventType.CLICK,
      selector: `[${VALID_ATTRIBUTE_SELECTORS.SIGN_OUT_BUTTON}]`,
      callback: handleSignOutClick,
    });

    return () => {
      removeSubscriber('sign-in-triggers');
      removeSubscriber('sign-in-triggers-rownd');
      removeSubscriber('sign-out-triggers');
      removeSubscriber('tickets-open-triggers');
    };
  }, [handleSignInClick, handleSignOutClick, handleTicketsOpen]);

  return null;
};

export default InitDomListeners;
