import { useOidc } from '@/scripts/hooks';
import { useGlobalContext } from '@/scripts/hooks/use-global-context';
import { useTranslation, Trans } from 'preact-i18next';
import * as Selectors from '@/scripts/utils/Selectors';
import { useMemo } from 'preact/hooks';

export default function OidcClientHeader() {
  const { state } = useGlobalContext();
  const oidc = useOidc();
  const { t } = useTranslation();
  const appIcon = Selectors.appIcon(state);

  const clientName = useMemo(() => oidc.config?.client?.client_name ?? 'unknown', [oidc.config?.client?.client_name]);
  const appName = useMemo(() => state.app.name ?? 'unknown', [state.app.name]);

  return (
    <div className="rph-oidc-client-header">
      {oidc.config?.client?.logo_uri && (
        <div className="rph-oidc-client-header__logo">
          <img src={oidc.config?.client?.logo_uri} alt="Client logo" />
        </div>
      )}
      <h2 className="rph-oidc-client-header__prompt">
        {oidc.config?.client?.hub_title ? (
          oidc.config?.client?.hub_title
        ) : (
          <Trans
            t={t}
            i18nKey="Sign in to {{clientName}} with {{appName}}"
            values={{
              clientName: oidc.config?.client?.client_name ?? 'unknown',
              appName: state.app.name ?? 'unknown'
            }}
            >
            Sign in to <strong>{clientName}</strong> with <strong>{appName}</strong>
          </Trans>
        )}
      </h2>
      {oidc.config?.client?.hub_show_logos && appIcon && (
        <>
          <div className="rph-oidc-client-header__divider" />
          <div className="rph-oidc-client-header__logo">
            <img src={appIcon} alt="App logo" />
          </div>
        </>
      )}
    </div>
  );
}
