import { logger } from './log';

export async function importWithRetry(importFn: () => Promise<any>, maxRetries = 3, delay = 1000) {
    let retries = 0;
    while (retries < maxRetries) {
      try {
        return await importFn();
      } catch (error) {
        logger.warn(`Import failed, attempt ${retries + 1}: ${error}`);
        retries++;
        if (retries === maxRetries) {
          throw error;
        }
        await new Promise((resolve) => setTimeout(resolve, delay));
      }
    }
  }
