import { useCallback, StateUpdater } from 'preact/hooks';
import { ActionType, useGlobalContext } from './use-global-context';
import { LoginStep, SignInState } from '../Login';
import { events, EventType } from '../events';
import useSafeLocation from './use-window-location';
import useAutomations from '../Automations/hooks';
import useHandleClose from './use-handle-close';
import useOidc from './use-oidc';

interface UseSuccessSignInProps {
  setSignInState?: StateUpdater<SignInState>;
  disablePostSignInAutomation?: boolean;
  postRegistrationUrl?: string;
  navToLocation?: () => void;
}

export default function useSuccessSignIn() {
  const { state, dispatch } = useGlobalContext();
  const safeLocation = useSafeLocation();
  const { nav } = state;
  const { hasPostSignInAutomation } = useAutomations();
  const oidc = useOidc();
  const handleClose = useHandleClose();

  const postSignIn = useCallback(
    ({ setSignInState, disablePostSignInAutomation, postRegistrationUrl, navToLocation }: UseSuccessSignInProps) => {
      const runAfterSignIn = () => {
        setTimeout(
          () => {
            if (navToLocation) {
              navToLocation();
            } else {
              handleClose();
            }

            // Reset modal state to prep for the next sign in
            setTimeout(() => {
              if (setSignInState) setSignInState({ step: LoginStep.INIT });
            }, 250);

            const redirectUrl =
              postRegistrationUrl || (nav.options as any)?.post_login_redirect || state.config?.postLoginUrl;
            // The mobile app will handle the rest of the post-login flow
            if (state.config?.displayContext === 'mobile_app') {
              return;
            }

            // Go to the redirect url if we're not in an oidc interaction
            if (!oidc.is_interaction_in_progress && redirectUrl) {
              safeLocation.assign(redirectUrl);
            }
          },
          disablePostSignInAutomation ? 0 : 1500,
        );
      };

      // POST SIGN IN AUTOMATION
      if (hasPostSignInAutomation && !disablePostSignInAutomation) {
        return;
      }
      if (!state.is_post_sign_in_requirements_done) {
        dispatch({
          type: ActionType.SET_IS_POST_SIGN_IN_REQUIREMENTS_DONE,
          payload: true,
        });
      }

      // POST AUTHENTICATION API
      if (state.is_waiting_for_post_authentication_api) {
        if (setSignInState) {
          setSignInState((prev) => ({ ...prev, waitingForPostSignInApi: true }));
        }
        events.addEventListener(EventType.POST_AUTHENTICATION_API_REQUEST_COMPLETE, () => {
          if (setSignInState) {
            setSignInState((prev) => ({ ...prev, waitingForPostSignInApi: false }));
          }
          runAfterSignIn();
        });
        return;
      }

      runAfterSignIn();
    },
    [
      dispatch,
      handleClose,
      hasPostSignInAutomation,
      (nav.options as any)?.post_login_redirect,
      state.config?.displayContext,
      state.config?.postLoginUrl,
      state.is_post_sign_in_requirements_done,
      state.is_waiting_for_post_authentication_api,
      safeLocation,
    ],
  );

  return postSignIn;
}
