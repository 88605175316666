import { useCallback } from 'preact/hooks';
import { useGlobalContext, ActionType, type SignInMethods } from './use-global-context';
import type { RequestSignInIntent, Status } from '../ExternalApi';
import type { UserType } from '../DefaultContext';
import type { LoginStep } from '../Login';
import type { WalletTypes, Wallet } from '../Components/Profile/ProfileWallets/ProfileWallets';
import type { CreateNamedAccountStep } from '../Components/WalletDetails/Near/CreateNamedAccount/CreateNamedAccount';
import type { RequestFormTypes } from '../Components/RequestForm/RequestForm';
import type { Options } from 'qr-code-styling';
import type { FieldProps } from '../Components/CollectInformation/CollectInformation';
import type { LegalDocs } from '../Components/LegalOptIn/LegalOptIn';
import type { ConnectAuthenticatorTypes } from '../Components/ConnectAuthenticator/ConnectAuthenticator';

export type DefaultRouteOptions = {
  /**
   * Open the Hub in a modal/bottom sheet
   * @default true
   */
  use_modal?: boolean;
  /**
   * Show or hide the Hub container
   * @default true
   */
  is_container_visible?: boolean;
  /**
   * Do not display a close button or allow closing of the Hub
   * @default undefined
   */
  prevent_closing?: boolean;
};

type DefaultAccountRouteOptions = DefaultRouteOptions;

export type RouteOptions = {
  '/': Record<string, unknown>;
  '/welcome': Record<string, unknown>;
  '/account': DefaultAccountRouteOptions;
  '/account/*': DefaultAccountRouteOptions;
  '/account/login': {
    /** Redirect the user to this location after successfully signing in. This location is one of the valid Hub routes (e.g. /account/manage) */
    post_sign_in_location?: keyof RouteOptions;
    /** Automatically sign the user in if possible. A user identifier must be set the profile data in local storage */
    auto_sign_in?: boolean;
    /** Add any user data in local storage to the sign in request */
    include_user_data?: boolean;
    /** Do not redirec the user after successfully signing in */
    redirect?: boolean;
    login_step?: LoginStep;
    group_to_join?: string;
    intent?: RequestSignInIntent;
    sign_in_type?: keyof SignInMethods;
    error_message?: string;
    request_id?: string;
    use_explicit_sign_up_flow?: boolean;
    token?: string;
    identifier?: string;
    user_type?: UserType;
    app_variant_user_type?: UserType;
    instant_user?: {
      enabled?: boolean;
      auto_delete?: string;
    };
    init_data?: Record<string, any>;
    default_values?: Record<string, any>;
    user_data?: Record<string, any>;
    post_login_redirect?: string;
    title?: string;
    subtitle?: string;
    success_message?: string;
    wallet_provider_scopes?: string[];
    continue_with_email?: boolean;
  } & DefaultAccountRouteOptions;
  '/account/manage': {
    visible_profile_fields?: string[];
    post_login_redirect?: string;
    auto_focus_field?: string;
    post_verify_redirect?: string;
  };
  '/account/connectAuthenticator': {
    status?: Status;
    error?: string;
    group_to_join?: string;
    type?: ConnectAuthenticatorTypes;
  } & DefaultAccountRouteOptions;
  '/account/requestFields': {
    fields?: string[];
    buttonText?: string;
    questionText?: string;
  } & DefaultAccountRouteOptions;
  '/account/connectForm': Record<string, unknown> & DefaultAccountRouteOptions;
  '/account/near/createNamedAccount': {
    type?: WalletTypes;
    wallet?: Wallet;
    step?: CreateNamedAccountStep;
  } & DefaultAccountRouteOptions;
  '/account/walletDetails': {
    type?: WalletTypes;
    wallet?: Wallet;
  } & DefaultAccountRouteOptions;
  '/account/tickets': Record<string, unknown> & DefaultAccountRouteOptions;
  '/account/collectInformation': {
    title?: string;
    subtitle?: string;
    fields?: FieldProps[];
    button?: string;
  } & DefaultAccountRouteOptions;
  '/account/merge': Record<string, unknown> & DefaultAccountRouteOptions;
  '/account/delete': Record<string, unknown> & DefaultAccountRouteOptions;
  '/account/requestForm': {
    type?: RequestFormTypes;
  } & DefaultAccountRouteOptions;
  '/account/legalOptIn': {
    legal_docs?: LegalDocs[];
    title?: string;
    subtitle?: string;
  } & DefaultAccountRouteOptions;
  '/oidc': Record<string, unknown>;
  '/google': {
    user_type?: UserType;
    app_variant_user_type?: UserType;
    login_step?: LoginStep;
    group_to_join?: string;
    intent?: RequestSignInIntent;
    purpose?: 'authentication' | 'connect_account';
    identifier?: string;
    post_sign_in_location?: keyof RouteOptions;
    suggest_google_sign_in_based_on_email?: boolean;
  };
  '/qrcode': {
    data?: string;
    overrides?: Options;
  };
  '/success': {
    duration?: number;
  };
};

export type RouteOptionsWithDefault<T extends keyof RouteOptions> = RouteOptions[T] & DefaultRouteOptions;

export default function useRoute() {
  const { dispatch } = useGlobalContext();

  return {
    navTo: useCallback(
      <T extends keyof RouteOptions>(route: T, trigger = 'nav', opts?: RouteOptionsWithDefault<T>) => {
        dispatch({
          type: ActionType.CHANGE_ROUTE,
          payload: {
            route,
            trigger: trigger || 'nav',
            event_id: Math.random().toString(36).slice(2),
            opts: {
              ...opts,
              use_modal: opts?.use_modal ?? true,
              is_container_visible: opts?.is_container_visible ?? true,
            },
          },
        });
      },
      [dispatch],
    ),
    setPopupRoute: useCallback(
      (popup_route?: string) => {
        dispatch({
          type: ActionType.SET_POPUP_ROUTE,
          payload: {
            popup_route,
          },
        });
      },
      [dispatch],
    ),
  };
}
