import { useGlobalContext } from '@/scripts/hooks/use-global-context';
import { importWithRetry } from '@/scripts/utils/import-with-retry';
import { logger } from '@/scripts/utils/log';
import classnames from 'classnames';
import ky from 'ky';
import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';

interface LottieAnimationProps {
  customClass?: string;
  type?: LottieTypes;
  path?: string;
  animationData?: string;
  fallback?: any;
  loop?: boolean;
  style?: string;
}

export enum LottieTypes {
  Swoops = 'swoops.json',
  CheckMark = 'check-mark.json',
}

export default function LottieAnimation({
  customClass,
  type,
  animationData,
  path,
  loop = false,
  style,
  fallback = undefined,
}: LottieAnimationProps) {
  const { state } = useGlobalContext();
  const [id] = useState(Math.floor(Math.random() * 10000000));
  const [showFallback, setShowFallback] = useState(false);

  useEffect(() => {
    const lottieContainer = document.getElementById(`rph-lottie-animation-${id}`);
    if (!lottieContainer) return;

    const loadLottieAnimation = async () => {
      let Lottie: any;
      try {
        Lottie = await importWithRetry(() => import('lottie-web'));

        let animationDataForType: Record<string, any> = {};
        if (type) {
          const response = await ky.get(`${state.config?.baseUrl}/static/${type}`);
          animationDataForType = await response.json();
        }

        if (animationData || type || path) {
          Lottie.default.loadAnimation({
            container: lottieContainer,
            renderer: 'svg',
            loop,
            autoplay: true,
            ...{
              path: path || undefined,
              animationData: path ? undefined : animationDataForType || animationData,
            },
          });
        }
      } catch (err) {
        setShowFallback(true);
        logger.error('lottie-web package did not load');
      }
    };

    loadLottieAnimation();
  }, [animationData, id, loop, path, state.config?.baseUrl, type]);

  if (showFallback && fallback) return fallback;

  return (
    <div
      class={classnames('rph-lottie-animation', {
        [`${customClass}`]: customClass,
      })}
      style={style}
      id={`rph-lottie-animation-${id}`}
    />
  );
}
