import { h } from 'preact';
import CheckmarkFilled from './assets/svg/CheckmarkFilled';
import ContainerInner from './ContainerInner';
import { useRoute } from './hooks';
import { useGlobalContext } from './hooks/use-global-context';
import type { RouteOptions } from './hooks/use-route';

export default function Success() {
  const { state } = useGlobalContext();
  const options = state.nav.options as RouteOptions['/success'];
  const { navTo } = useRoute();

  // Close after a period of time
  setTimeout(() => {
    navTo('/', 'success', {
      is_container_visible: false,
    });
  }, options.duration || 2000); // 2 second default

  return (
    <ContainerInner>
      {() => (
        <div className="rph-success-indicator">
          <CheckmarkFilled />
        </div>
      )}
    </ContainerInner>
  );
}
