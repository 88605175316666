import { h } from 'preact';
import { useGlobalContext, type GlobalStateNav } from '@/scripts/hooks/use-global-context';
import RequestTermsConditions from './RequestTermsConditions/RequestTermsConditions';

export enum RequestFormTypes {
  TermsAndConditions = 'TermsAndConditions',
  Unknown = 'Unknown',
}

export default function RequestForm() {
  const { state } = useGlobalContext();
  const nav = state.nav as GlobalStateNav<'/account/requestForm'>;
  const { options } = nav;
  let requestFormType: RequestFormTypes = RequestFormTypes.TermsAndConditions;
  if (options?.type && Object.values(RequestFormTypes).includes(options.type)) {
    requestFormType = options.type;
  }

  const RequestFormComponent = () => ({
    [RequestFormTypes.TermsAndConditions]: <RequestTermsConditions />,
    [RequestFormTypes.Unknown]: <div />,
  });

  return RequestFormComponent()[requestFormType];
}
