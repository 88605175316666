import isEqual from 'lodash-es/isEqual';
import { useCallback, useEffect, useRef, useState } from 'preact/hooks';
import { IConfig } from '.';
import { useApi, useRoute, useNear, useGroups, usePasskeys, useOidc } from './hooks';
import useFingerprint from './hooks/use-fingerprint';
import { logger } from './utils/log';
import { useGlobalContext, ActionType, AppConfig, AppSchema } from './hooks/use-global-context';
import useMerge from './hooks/use-merge';
import { RequestSignInIntent } from './ExternalApi';
import { LoginStep } from './Login';
import useUserApi from './hooks/use-user-api';
import { useInstantUser } from './hooks/use-instant-user';
import useRphHash from './hooks/use-rph-hash';
import { ROWND_LINKS } from './utils/links';
import { useTranslation } from 'preact-i18next';
import { RowndApiErrorCodes } from './hooks/use-api';
import { SignInErrorMessages, useSignInErrorMessages } from './Components/Login/Error/Error';
import usePages from './hooks/use-pages';
import { SignInMethodTypes } from './hooks/use-sign-in';
import { events, EventType } from './events';

type DefaultContextProps = {
  config: IConfig;
};

type AppConfigResp = {
  app: {
    id: string;
    name: string;
    icon: string;
    schema: AppSchema;
    config: AppConfig;
  };
};

export type UserInfoResp = {
  data: {
    [key: string]: any;
  };
  redacted: string[];
};

export enum DefaultContextIntent {
  SignUp = 'sign_up',
  SignIn = 'sign_in',
}

export enum UserType {
  NewUser = 'new_user',
  ExistingUser = 'existing_user',
  InstantUser = 'instant_user',
}

export default function DefaultContext({ config }: DefaultContextProps) {
  const { state, dispatch } = useGlobalContext();
  const { client: api } = useApi();
  const { navTo } = useRoute();
  const { t } = useTranslation();
  useFingerprint();
  useMerge();
  useNear();
  const { acceptInvite } = useGroups();
  const { fetchPasskeyRegistrations } = usePasskeys();
  const { retrieveUserInfo } = useUserApi();
  const oidc = useOidc();
  const { isInstantUsersEnabled, instantUsersFallback, createInstantUser } = useInstantUser();
  const rphHash = useRphHash();
  const [creatingInstantUserStatus, setCreatingUserStatus] = useState<undefined | 'loading' | 'error'>(undefined);
  const signInErrorMessages = useSignInErrorMessages();
  const { fetchPages } = usePages();

  // eslint-disable-next-line no-undef
  const metaTimerId = useRef<undefined | NodeJS.Timeout>(undefined);

  //Listens to state changes for when a new instant user should be created.
  useEffect(() => {
    if (
      state.app.config &&
      !state.auth.access_token &&
      isInstantUsersEnabled &&
      !rphHash?.rph_init &&
      !rphHash?.rph_invite &&
      !creatingInstantUserStatus
    ) {
      if (state.sign_in.last_sign_in && state.sign_in.last_sign_in_date) {
        // Fallback if there's an existing sign-in method so we can ask the previous user to sign in.
        instantUsersFallback();
        return;
      }

      (async () => {
        setCreatingUserStatus('loading');
        const res = await createInstantUser();
        if (res?.error) {
          setCreatingUserStatus('error');
          instantUsersFallback();
          return;
        }
        setCreatingUserStatus(undefined);
      })();
    }
  }, [
    createInstantUser,
    creatingInstantUserStatus,
    instantUsersFallback,
    isInstantUsersEnabled,
    rphHash?.rph_init,
    rphHash?.rph_invite,
    state.app.config,
    state.auth.access_token,
    state.sign_in.last_sign_in,
    state.sign_in.last_sign_in_date,
  ]);

  // Fetch app schema and config
  useEffect(() => {
    (async () => {
      const resp: AppConfigResp = await api
        .get('hub/app-config', {
          headers: {
            'x-rownd-app-key': config.appKey,
          },
        })
        .json();

      dispatch({
        type: ActionType.SET_APP_CONFIG,
        payload: resp.app,
      });
    })();
  }, [api, config.appKey, dispatch]);

  useEffect(() => {
    fetchPages();
  }, [fetchPages]);

  useEffect(() => {
    fetchPasskeyRegistrations();
  }, [fetchPasskeyRegistrations]);

  useEffect(() => {
    // This method is now async, so we wrap it in an anonymous function
    // so that `useEffect` is happy and doesn't expect a cleanup function.
    retrieveUserInfo();
  }, [retrieveUserInfo]);

  useEffect(() => {
    if (!state.user.needs_refresh) {
      return;
    }
    retrieveUserInfo();
    dispatch({
      type: ActionType.SET_REFRESH_USER_DATA,
      payload: { needs_refresh: false },
    });
  }, [dispatch, retrieveUserInfo, state.user.needs_refresh]);

  const _saveUserMeta = useCallback(() => {
    if (!state.auth.access_token) {
      return;
    }

    (async () => {
      await api
        .put(`me/meta`, {
          authenticated: true,
          json: {
            meta: state.user.meta,
          },
        })
        .json();
    })();
  }, [api, state.auth.access_token, state.user.meta]);

  const saveUserMetaDebounced = useCallback(() => {
    clearTimeout(metaTimerId.current);
    metaTimerId.current = setTimeout(() => _saveUserMeta(), 1000);
  }, [_saveUserMeta]);

  // Update user meta when changed
  const userMetaRef = useRef(state.user.meta);
  useEffect(() => {
    if (state.is_loading_user_data || isEqual(userMetaRef.current, state.user.meta)) {
      return;
    }

    saveUserMetaDebounced();
    userMetaRef.current = state.user.meta;
  }, [saveUserMetaDebounced, state.is_loading_user_data, state.user.meta]);

  const setIsPostSignInRequirementsDone = useCallback(
    (isDone: boolean) => {
      dispatch({
        type: ActionType.SET_IS_POST_SIGN_IN_REQUIREMENTS_DONE,
        payload: isDone,
      });
    },
    [dispatch],
  );

  // Handle rph_init and rph_invite in the url hash
  const handleRphHash = useCallback(async () => {
    if (!rphHash || !config.locationHash) {
      return;
    }

    const acceptInviteIfPresent = async () => {
      try {
        if (!rphHash.rph_invite || state.is_accepting_group_invite) {
          return;
        }

        // Accept a group invite if present
        await acceptInvite({
          id: rphHash.rph_invite.id,
          app_id: rphHash.rph_invite.app_id,
          group_id: rphHash.rph_invite.group_id,
          access_token: rphHash.rph_init?.access_token,
        });
      } catch (err) {
        logger.error('Failed to accept invitation', err);
      }
    }

    if (rphHash.rph_init?.error) {
      // TODO: Display the error
      instantUsersFallback();
      logger.error('Error in rph_init:', rphHash.rph_init.error);

      let message = rphHash.rph_init.error.message;
      const code = rphHash.rph_init.error.code;
      switch (code) {
        case RowndApiErrorCodes.E_USER_PROFILE_DISABLED:
          message = t(
            'This account is currently disabled. Please try a different account or contact {{support_email}}',
            {
              support_email: state.app.config?.hub?.legal?.support_email || ROWND_LINKS.SUPPORT_EMAIL,
            },
          );
          break;
        case RowndApiErrorCodes.E_RESTRICT_EMAIL_DOMAIN:
          message = signInErrorMessages[SignInErrorMessages.UnauthorizedEmail];
          break;
        case RowndApiErrorCodes.E_RESTRICT_SIGN_UPS:
          message = signInErrorMessages[SignInErrorMessages.SignUpDisabled];
          break;
        default:
          break;
      }

      navTo('/account/login', 'rph-init', {
        login_step: LoginStep.ERROR,
        error_message: message,
        sign_in_type: rphHash.rph_init.error.type,
        use_modal: true,
      });
      return;
    } else if (rphHash.rph_init) {
      // If no account found for rphInit, show No Account Found login Modal
      if (
        rphHash.rph_init.intent === DefaultContextIntent.SignIn &&
        rphHash.rph_init.token &&
        rphHash.rph_init.user_type === UserType.NewUser
      ) {
        navTo('/account/login', 'rph-init', {
          include_user_data: true,
          intent: RequestSignInIntent.SignIn,
          token: rphHash.rph_init.token,
          login_step: LoginStep.NO_ACCOUNT,
          user_data: rphHash.rph_init.user_data,
          use_modal: true,
        });
      } else {
        // Ensure required properties are set
        if (
          !(
            rphHash.rph_init.access_token &&
            rphHash.rph_init.refresh_token &&
            rphHash.rph_init.app_id &&
            rphHash.rph_init.app_user_id
          ) &&
          state.config?.displayContext !== 'mobile_app'
        ) {
          instantUsersFallback();
          logger.error('Required properties missing from rph_init', rphHash.rph_init);
          return;
        }

        if (rphHash.rph_init.reset_post_sign_in_reqs === true) {
          setIsPostSignInRequirementsDone(!!rphHash.rph_init.access_token);
        }

        await acceptInviteIfPresent();

        // Default rphInit sign-in
        dispatch({
          type: ActionType.LOGIN_SUCCESS,
          payload: {
            access_token: rphHash.rph_init.access_token,
            refresh_token: rphHash.rph_init.refresh_token,
            app_id: rphHash.rph_init.app_id,
            app_user_id: rphHash.rph_init.app_user_id,
          },
        });

        // Show success modal after an rphInit sign-in, only for web and mobile apple sign-in
        if (
          state.config?.displayContext !== 'mobile_app' ||
          (rphHash.rph_init.last_sign_in === 'apple' &&
            window?.rowndAndroidSDK &&
            rphHash.rph_init.user_type &&
            rphHash.rph_init.access_token)
        ) {
          events.dispatch(EventType.SIGN_IN_COMPLETED, {
            method: SignInMethodTypes.APPLE,
            user_type: rphHash.rph_init.user_type,
            app_variant_user_type: rphHash.rph_init.app_variant_user_type,
          });

          navTo('/account/login', 'rph-init', {
            use_modal: true,
            login_step: LoginStep.SUCCESS,
            intent: rphHash.rph_init.intent as unknown as RequestSignInIntent | undefined,
            user_type: rphHash.rph_init.user_type,
            app_variant_user_type: rphHash.rph_init.app_variant_user_type,
          });
        }
      }

      // Save rphInit sign in method
      if (rphHash.rph_init.last_sign_in) {
        dispatch({
          type: ActionType.SET_SIGN_IN_METHOD,
          payload: {
            last_sign_in: rphHash.rph_init.last_sign_in,
            last_sign_in_date: rphHash.rph_init?.last_sign_in_date,
          },
        });
      }
    } else {
      await acceptInviteIfPresent();
    }

    // Clean up URL hash
    const newHash = removeRphHashValues(config.locationHash);

    // If the resulting hash is empty, drop it.
    window.history.pushState(
      '',
      document.title,
      window.location.pathname + window.location.search + (newHash.endsWith('#') ? '' : newHash),
    );

    if (oidc.is_interaction_in_progress) {
      return navTo('/oidc', void 0, {
        prevent_closing: true,
        is_container_visible: true,
      });
    }
  }, [
    rphHash,
    config.locationHash,
    state.is_accepting_group_invite,
    state.app.config?.hub?.legal?.support_email,
    state.config?.displayContext,
    acceptInvite,
    instantUsersFallback,
    navTo,
    t,
    signInErrorMessages,
    dispatch,
    setIsPostSignInRequirementsDone,
    oidc.is_interaction_in_progress,
  ]);

  useEffect(() => {
    handleRphHash();
  }, [handleRphHash]);

  // This useEffect is specific for mobile.
  // It's looking for a URL Param (sign_in) which provides previous login information
  useEffect(() => {
    if (state.config?.displayContext !== 'mobile_app') return;

    const signInParamBase64 = new URLSearchParams(window.location.search).get('sign_in');
    if (!signInParamBase64) return;

    const signInParamHash = atob(signInParamBase64).toString();
    const signInParam: SignIn = JSON.parse(signInParamHash);
    if (signInParam?.last_sign_in) {
      dispatch({
        type: ActionType.SET_SIGN_IN_METHOD,
        payload: { last_sign_in: signInParam?.last_sign_in, last_sign_in_date: signInParam?.last_sign_in_date },
      });
    }
    const activeAccounts = signInParam.android?.active_accounts;
    if (activeAccounts) {
      dispatch({
        type: ActionType.SET_ANDROID_ACTIVE_ACCOUNTS,
        payload: activeAccounts,
      });
    }
  }, [dispatch, state.config?.displayContext]);

  return null;
}

interface SignIn {
  last_sign_in?: string;
  last_sign_in_date?: string;
  android?: {
    active_accounts?: { email?: string }[];
  };
}

function removeRphHashValues(hash: string, values?: ('rph_init' | 'rph_invite')[]): string {
  values = values || ['rph_init', 'rph_invite'];
  const regeXTmpl = `,*(${values.join('|')})=[\\w\\d\\-=.]*`;
  const regex = new RegExp(regeXTmpl, 'g');
  return hash.replace(regex, '');
}
