import { useCallback } from 'preact/hooks';
import { useGlobalContext } from './use-global-context';

export const MOBILE_APP_DEFAULT_REDIRECT = 'NATIVE_APP';

const useReturnUrl = () => {
  const { state } = useGlobalContext();

  const returnUrlWithDefault = useCallback(
    (returnUrl: string | undefined) => {
      const getDefault = () => {
        // If coming from a magic link, no redirect is needed
        if (state.config?.locationHash?.includes('#rph_init')) {
          return null;
        }

        if (state.config?.postLoginUrl) {
          return state.config?.postLoginUrl;
        }

        if (state.app.config?.default_redirect_url) {
          return state.app.config?.default_redirect_url;
        }

        if (state.config?.displayContext === 'mobile_app') {
          return MOBILE_APP_DEFAULT_REDIRECT;
        }
      };

      let url = returnUrl || getDefault();
      if (url && !url.match(/^[a-zA-Z]+:\/\//) && url?.toUpperCase() !== MOBILE_APP_DEFAULT_REDIRECT) {
        url = window.location.origin + url;
      }

      return url || window.location.href;
    },
    [state.app.config?.default_redirect_url, state.config?.displayContext, state.config?.postLoginUrl]
  );

  return {
    returnUrlWithDefault,
  };
};

export default useReturnUrl;
