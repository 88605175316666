import { h, JSX } from 'preact';
import Button from '@/scripts/Components/Common/Button/Button';
import { useGlobalContext } from '@/scripts/hooks/use-global-context';
import OidcClientHeader from '../OidcClientHeader/OidcClientHeader';
import { useTranslation, Trans } from 'preact-i18next';
import { useOidc } from '@/scripts/hooks';
import { useState } from 'preact/hooks';

const ScopeDescriptions = {
  profile: 'View your profile information',
  email: 'View your email address',
  phone: 'View your phone number',
};

export default function OidcConsent({ handleCancel }: { handleCancel: () => void }) {
  const { state } = useGlobalContext();
  const { t } = useTranslation();
  const oidc = useOidc();

  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = () => {
    setIsLoading(true);
  };

  const app = state.app;
  const clientName = oidc.config?.client?.client_name ?? 'unknown';
  const interaction = oidc.config?.interaction;
  const confirmEndpoint = oidc.config?.confirm_endpoint ?? '';

  const missingScopes = new Set<string>((interaction?.prompt?.details?.missingOIDCScope as string[]) ?? []);
  missingScopes.delete('openid');
  missingScopes.delete('offline_access');

  // const missingClaims = new Set<string>(interaction.prompt.details.missingOIDCClaims);
  // ['sub', 'sid', 'auth_time', 'acr', 'amr', 'iss'].forEach(Set.prototype.delete.bind(missingClaims));

  // const issingResourceScopes = interaction.prompt.details.missingResourceScopes;

  return (
    <div className="rph-oidc-consent">
      <OidcClientHeader />
      <h2 className="rph-oidc-consent__title">
        <Trans
          t={t}
          i18nKey="{{clientName}} wants to access your {{appName}} account"
          values={{ clientName, appName: app.name }}
        >
          {clientName} wants to access your {app.name} account
        </Trans>
      </h2>
      {missingScopes.size > 0 && (
        <div className="rph-oidc-consent__missing-scopes-container">
          <h4 className="rph-oidc-consent__description">
            <Trans t={t} i18nKey="This will allow {{clientName}} to:" values={{ clientName }}>
              This will allow {clientName || 'unknown'} to:
            </Trans>
          </h4>
          <ul className="rph-oidc-consent__missing-scopes">
            {Array.from(missingScopes).map((scope) => (
              <li key={scope}>{ScopeDescriptions[scope as keyof typeof ScopeDescriptions]}</li>
            ))}
          </ul>
        </div>
      )}
      <form autocomplete="off" action={confirmEndpoint} method="post">
        <Button
          type="primary"
          isSubmit={true}
          label={t('Allow')}
          isLoading={isLoading}
          handleOnClick={handleSubmit}
          disabled={isLoading}
        />
        <Button type="tertiary" label={t('Cancel')} handleOnClick={handleCancel} />
      </form>
    </div>
  );
}
