import { useCallback } from 'preact/hooks';
import { useGlobalContext } from './use-global-context';
import { logger } from '../utils/log';
import { RequestSignInIntent } from '../ExternalApi';
import { useInstantUser } from './use-instant-user';

export type AppleSignInFunction = (opts: {
  purpose?: 'authentication' | 'connect_account';
  intent?: RequestSignInIntent;
  userData?: Record<string, any>;
  groupToJoin?: string;
}) => void;

export default function useAppleSignIn(): {
  authenticate: AppleSignInFunction;
} {
  const { state } = useGlobalContext();
  const { instantUserId } = useInstantUser();
  const { app } = state;

  const authenticate = useCallback(
    ({
      purpose,
      intent,
      userData,
      groupToJoin,
    }: {
      purpose?: 'authentication' | 'connect_account';
      intent?: RequestSignInIntent;
      userData?: Record<string, any>;
      groupToJoin?: string;
    }) => {
      purpose = purpose || 'authentication';

      if (!app.id) {
        return;
      }

      const clientId = app?.config?.hub?.auth?.sign_in_methods?.apple?.client_id;
      if (!clientId) {
        return logger.error('Unable to perform sign in with Apple. Missing client_id');
      }

      // There isn't any harm in calling this multiple times
      (window as any).AppleID?.auth?.init({
        clientId,
        scope: 'name email',
        redirectURI: state.config?.appleIdCallbackUrl,
        state: JSON.stringify({
          app_key: state.config?.appKey,
          app_id: app.id,
          return_to: window.location.href,
          purpose,
          intent,
          user_data: userData,
          group_to_join: groupToJoin,
          instant_user_id: instantUserId,
        }),
      });

      (window as any).AppleID?.auth?.signIn();
    },
    [
      app?.config?.hub?.auth?.sign_in_methods?.apple?.client_id,
      app.id,
      instantUserId,
      state.config?.appleIdCallbackUrl,
    ],
  );

  return {
    authenticate,
  };
}
