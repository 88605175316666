import React from 'preact';

function RenewSVG() {
  return (
    <svg id="renew-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <defs>
        <style>{`.cls-1{fill:none;}`}</style>
      </defs>
      <title>renew</title>
      <path fill="currentColor" d="M12,10H6.78A11,11,0,0,1,27,16h2A13,13,0,0,0,6,7.68V4H4v8h8Z" />
      <path fill="currentColor" d="M20,22h5.22A11,11,0,0,1,5,16H3a13,13,0,0,0,23,8.32V28h2V20H20Z" />
      <g id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;">
        <rect className="cls-1" width="32" height="32" />
      </g>
    </svg>
  );
}

export default RenewSVG;
