/**
 * Decompress a base64 encoded string using gzip
 * @param base64 - The base64 encoded string to decompress
 * @returns The decompressed string
 */
export async function decompressString(base64: string): Promise<string> {
  // Convert base64 to Uint8Array
  const binaryStr = atob(base64);
  const bytes = new Uint8Array(binaryStr.length);
  for (let i = 0; i < binaryStr.length; i++) {
    bytes[i] = binaryStr.charCodeAt(i);
  }

  // Create a decompression stream
  const ds = new DecompressionStream('gzip');
  const writer = ds.writable.getWriter();
  const reader = ds.readable.getReader();

  // Write the compressed data
  writer.write(bytes);
  writer.close();

  // Read the decompressed data
  const chunks = [];
  while (true) {
    const { value, done } = await reader.read();
    if (done) break;
    chunks.push(value);
  }

  // Concatenate chunks
  const decompressedData = new Uint8Array(
    chunks.reduce((acc, val) => acc + val.length, 0)
  );
  let offset = 0;
  for (const chunk of chunks) {
    decompressedData.set(chunk, offset);
    offset += chunk.length;
  }

  // Convert back to string
  return new TextDecoder().decode(decompressedData);
}
