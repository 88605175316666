import { h } from 'preact';
import { useCallback, useEffect, useMemo, useState } from 'preact/hooks';
import { useGlobalContext } from './hooks/use-global-context';
import useGoogleSignIn from './hooks/use-google-signin';

export default function GoogleOneTap() {
  const { state } = useGlobalContext();
  const { showOneTap } = useGoogleSignIn();

  const [hasLoadedOneTap, setHasLoadedOneTap] = useState(false);
  const [loadingOneTap, setLoadingOneTap] = useState(false);

  const cannotShowOneTap = useMemo(() => {
    return (
      state.is_initializing ||
      state.auth.access_token ||
      loadingOneTap ||
      hasLoadedOneTap ||
      state.is_container_visible ||
      state.app.config?.hub?.auth?.sign_in_methods?.google?.one_tap?.browser?.auto_prompt !== true
    );
  }, [
    hasLoadedOneTap,
    loadingOneTap,
    state.app.config?.hub?.auth?.sign_in_methods?.google?.one_tap?.browser?.auto_prompt,
    state.auth.access_token,
    state.is_initializing,
    state.is_container_visible
  ]);

  const runAfterTimeout = useCallback(() => {
    if (cannotShowOneTap) {
      return;
    }

    setLoadingOneTap(true);

    showOneTap({
      setHasLoadedOneTap,
      setLoadingOneTap,
      purpose: 'authentication',
    });
  }, [cannotShowOneTap, showOneTap, setHasLoadedOneTap, setLoadingOneTap]);

  useEffect(() => {
    // Show Google One-Tap after some time;
    const timeout = setTimeout(runAfterTimeout,
      state.app?.config?.hub?.auth?.sign_in_methods?.google?.one_tap?.browser?.delay || 7000
    ); // 7 seconds by default

    return () => {
      clearTimeout(timeout);
    }
  }, [
    cannotShowOneTap,
    setLoadingOneTap,
    runAfterTimeout,
    state.app?.config?.hub?.auth?.sign_in_methods?.google?.one_tap?.browser?.delay
  ]);

  useEffect(() => {
    if (window.google && hasLoadedOneTap && state.nav.current_route?.startsWith('/account/login')) {
      window.google.accounts.id.cancel();
    }
  }, [hasLoadedOneTap, state.nav.current_route]);

  return (
    <div
      id="rph-google-one-tap-prompt-parent"
      style="position: absolute; top: 100px; right: 400px; width: 0; height: 0; z-index: 1001;"
    />
  );
}
