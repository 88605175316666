import { useState, useEffect, useMemo } from 'preact/hooks';
import { DefaultContextIntent, type UserType } from '../DefaultContext';
import { useGlobalContext } from './use-global-context';
import { decompressString } from '../utils/compression';
import { logger } from '../utils/log';

interface ParseHashReturnValue {
  rph_init?: RphInit;
  rph_invite?: RphInvite;
  rph_oidc?: RphOidc;
}

enum RphInitErrorType {
  Google = 'google',
  Apple = 'apple',
  OAuth2 = 'oauth2',
}

interface RphInitError {
  type: RphInitErrorType;
  message: string;
  status_code: number;
  code?: string;
}

export interface RphInit {
  error?: RphInitError;
  intent?: DefaultContextIntent;
  token?: string;
  user_type?: UserType;
  app_variant_user_type?: UserType;
  user_data?: Record<string, any>;
  access_token?: string;
  refresh_token?: string;
  app_id?: string;
  app_user_id?: string;
  last_sign_in?: string;
  last_sign_in_date?: string;
  reset_post_sign_in_reqs?: boolean;
}

export interface RphInvite {
  id: string;
  app_id: string;
  group_id: string;
}

export interface RphOidc {
  client?: {
    application_type?: string;
    grant_types?: string[];
    id_token_signed_response_alg?: string;
    require_auth_time?: boolean;
    response_types?: string[];
    response_modes?: string[];
    subject_type?: string;
    token_endpoint_auth_method?: string;
    post_logout_redirect_uris?: string[];
    require_pushed_authorization_requests?: boolean;
    client_id?: string;
    client_name?: string;
    client_secret?: never;
    logo_uri?: string;
    logo_dark_mode_uri?: string;
    redirect_uris?: string[];
    [key: string]: unknown;
  };
  interaction?: {
    returnTo?: string;
    prompt?: {
      name: string;
      reasons: string[];
      details: Record<string, unknown>;
    };
    params?: {
      client_id: string;
      code_challenge: string;
      code_challenge_method: string;
      nonce: string;
      redirect_uri: string;
      response_type: string;
      scope: string;
      state: string;
    };
    session?: {
      accountId: string;
      uid: string;
      cookie: string;
    };
    cid?: string;
    kind?: string;
    jti?: string;
    exp?: number;
  };
  login_endpoint?: string;
  confirm_account_endpoint?: string;
  confirm_endpoint?: string;
}

function useRphHash() {
  const { state } = useGlobalContext();
  const [parsedHash, setParsedHash] = useState<ParseHashReturnValue | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function parseHashData() {
      if (isLoading || parsedHash) return;
      try {
        setIsLoading(true);
        if (!state.config?.locationHash) {
          setParsedHash(undefined);
          return;
        }

        const result = await parseHash(state.config.locationHash);
        setParsedHash(result);
      } catch (error) {
        logger.error('Error parsing hash:', error);
        setParsedHash(undefined);
      } finally {
        setIsLoading(false);
      }
    }

    parseHashData();
  }, [
    state.config?.locationHash,
    isLoading,
    setIsLoading,
    parsedHash,
    setParsedHash,
    parseHash
  ]);

  const result = useMemo(() => ({
    ...parsedHash,
    is_loading: isLoading,
  }), [parsedHash, isLoading]);

  return result;
}

export async function parseHash(hash: string): Promise<ParseHashReturnValue> {
  const re = RegExp(/(?<rph_key>rph_[\w]+)=(?<rph_value>[^,]+)/g);
  const hashMatches = [...hash.matchAll(re)];

  const result: ParseHashReturnValue = {};

  for (const match of hashMatches) {
    const key = match?.groups?.rph_key;
    const value = match?.groups?.rph_value;
    if (!(key && value)) {
      continue;
    }

    let decodedValue = '';
    if (value.startsWith('gz.')) {
      const encodedData = value.split('gz.')[1];
      const decompressedValue = await decompressString(encodedData);
      decodedValue = decompressedValue;
    } else {
      decodedValue = atob(value).toString();
    }

    if (key.startsWith('rph_init')) {
      result.rph_init = JSON.parse(decodedValue) as ParseHashReturnValue['rph_init'];
    } else if (key.startsWith('rph_invite')) {
      result.rph_invite = JSON.parse(decodedValue) as ParseHashReturnValue['rph_invite'];
    } else if (key.startsWith('rph_oidc')) {
      result.rph_oidc = JSON.parse(decodedValue) as ParseHashReturnValue['rph_oidc'];
    }
  }

  return result;
}

export default useRphHash;
