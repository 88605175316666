import { FunctionComponent } from 'preact';
import { SquadUpEventData } from '../useTickets';
import Ticket from './Ticket/Ticket';
import { useCallback, useRef, useState } from 'preact/hooks';
import { JSXInternal } from 'preact/src/jsx';
import useBottomSheet from '@/scripts/hooks/use-bottom-sheet';
import classNames from 'classnames';

interface EventProps {
  event: SquadUpEventData;
  index: number;
}

const Event: FunctionComponent<EventProps> = ({ event }) => {
  const { isBottomSheetEnabled } = useBottomSheet();

  const [activeScrollIndex, setActiveScrollIndex] = useState(0);
  const [showQRCodeForEveryTicket, setShowQRCodeForEveryTicket] = useState(false);
  const eventRef = useRef<HTMLDivElement>(null);
  const isTouching = useRef(false);

  const tickets = event.tickets;

  const snapScrollPositionWithIndex = useCallback((index: number) => {
    const width = eventRef.current?.getBoundingClientRect().width;
    if (!width || isTouching.current) return;

    setActiveScrollIndex(index);
    const snapPosition = index * (width - 12);

    eventRef.current.scrollTo({
      left: snapPosition,
      behavior: 'smooth',
    });
  }, []);

  const handleScroll = useCallback((e: JSXInternal.TargetedEvent<HTMLDivElement, Event>) => {
    const width = eventRef.current?.getBoundingClientRect().width;
    const scrollPosition = e.currentTarget.scrollLeft;
    if (!width || scrollPosition === undefined) return;

    const index = Math.round(scrollPosition / (width - 12));
    setActiveScrollIndex(index);
  }, []);

  if (tickets.length === 0) return null;
  return (
    <>
      {!isBottomSheetEnabled && (
        <div className="rph-event__title">
          {event.name} {`(${tickets.length})`}
        </div>
      )}
      <div ref={eventRef} className="rph-event" onScroll={handleScroll}>
        {tickets.map((ticket, index) => (
          <Ticket
            key={ticket.id}
            ticket={ticket}
            event={event}
            index={index}
            showQRCodeForEveryTicket={showQRCodeForEveryTicket}
            setShowQRCodeForEveryTicket={setShowQRCodeForEveryTicket}
          />
        ))}
        {isBottomSheetEnabled && <div className="rph-event__ticket-spacer" />}
      </div>
      {tickets.length > 1 && isBottomSheetEnabled ? (
        <div className="rph-event__scrollbar">
          {tickets.map((_, idx) => (
            <div
              className={classNames('rph-event__scrollbar__item', {
                'rph-event__scrollbar__item--active': idx === activeScrollIndex,
              })}
              onClick={() => snapScrollPositionWithIndex(idx)}
              key={idx}
            />
          ))}
        </div>
      ) : null}
    </>
  );
};

export default Event;
