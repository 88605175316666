import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'preact/hooks';
import { useRoute } from './hooks';
import { useGlobalContext, type GlobalStateNav } from './hooks/use-global-context';
import { LoginStep, SignInState } from './Login';
import { useTranslation } from 'preact-i18next';
import GoogleUser from './assets/svg/GoogleUser';
import useGoogleSignIn from './hooks/use-google-signin';
import { SignInSuccess } from './Components/Login/Success/Success';
import { SignInError } from './Components/Login/Error/Error';
import useSuccessSignIn from './hooks/use-success-signin';
import ContainerInner from './ContainerInner';
import Button from './Components/Common/Button/Button';
import classNames from 'classnames';
import { MessageType, sendMessageToApp } from './utils/mobile-app';
import { getUserAgentType, UserAgentType } from './utils';
import useSignIn from './hooks/use-sign-in';
import { UserType } from './DefaultContext';
import { RequestSignInIntent } from './ExternalApi';

export default function GoogleSignIn() {
  const { state } = useGlobalContext();
  const { config } = state;
  const nav = state.nav as GlobalStateNav<'/google'>;
  const { authenticate, showOneTap } = useGoogleSignIn();
  const { navTo } = useRoute();
  const successSignIn = useSuccessSignIn();
  const { initSignIn, isSubmitting: isSubmittingEmail, error: emailError } = useSignIn();
  const { t } = useTranslation();
  const shouldSuggestGoogleSignInBasedOnEmail = nav.options?.suggest_google_sign_in_based_on_email === true;
  const isMobileApp = config?.displayContext === 'mobile_app';

  const [loadingOneTap, setLoadingOneTap] = useState(false);
  const [signInState, setSignInState] = useState<SignInState>({ step: LoginStep.INIT });
  const [error, setError] = useState<null | string>(null);
  const [oneTapEnabled, setOneTapEnabled] = useState(getUserAgentType() !== UserAgentType.mobile);
  const [isExistingUser, setIsExistingUser] = useState(() => {
    if (nav.options?.app_variant_user_type) {
      return (
        nav.options?.app_variant_user_type === UserType.ExistingUser &&
        nav.options?.login_step === LoginStep.SUCCESS &&
        nav.options?.intent === RequestSignInIntent.SignUp
      );
    }
    return (
      nav.options?.user_type === UserType.ExistingUser &&
      nav.options?.login_step === LoginStep.SUCCESS &&
      nav.options?.intent === RequestSignInIntent.SignUp
    );
  });

  const identifier: string | undefined = useMemo(() => nav.options?.identifier, [nav.options?.identifier]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const setIframeHeight = useCallback((mutations: MutationRecord[]) => {
    const iframe: HTMLElement | null = document.querySelector('#credential_picker_container > iframe');
    if (!iframe) {
      return;
    }
    if (!iframe.getAttribute('style')?.includes('!important') && !['auto'].includes(iframe.style.height)) {
      iframe.setAttribute('style', `height: ${iframe.style.height} !important`);
    }
  }, []);

  useEffect(() => {
    if (isMobileApp) {
      // Disable mobile background touch to dismiss during sifwg
      sendMessageToApp({
        type: MessageType.CAN_TOUCH_BACKGROUND_TO_DISMISS,
        payload: {
          enable: shouldSuggestGoogleSignInBasedOnEmail ? 'false' : 'true',
        },
      });
    }

    return () => {
      sendMessageToApp({
        type: MessageType.CAN_TOUCH_BACKGROUND_TO_DISMISS,
        payload: {
          enable: 'true',
        },
      });
    };
  }, [signInState.step, isMobileApp, shouldSuggestGoogleSignInBasedOnEmail]);

  useLayoutEffect(() => {
    showOneTap({
      promptParentId: 'rph-sign-in-with-google-prompt-parent',
      setLoadingOneTap,
      setSignInState,
      setError,
      loginHint: identifier || undefined,
      postSignInLocation: nav.options?.post_sign_in_location,
      postLoginRedirect: nav.options?.post_login_redirect,
      promptCallback: (notification: any) => {
        if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
          if (shouldSuggestGoogleSignInBasedOnEmail) {
            setOneTapEnabled(false);
            return;
          }
          if (!state.auth.access_token) {
            return navTo('/account/login', 'google-sign-in', {
              use_modal: true,
              login_step: LoginStep.INIT,
            });
          }
          return navTo('/account/manage', 'google-sign-in', {
            use_modal: true,
          });
        }
        setIframeHeight([]);
      },
      groupToJoin: nav.options?.group_to_join,
      purpose: nav.options?.purpose,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIframeHeight]);

  useEffect(() => {
    if (signInState?.step === LoginStep.SUCCESS) {
      successSignIn({
        navToLocation: nav.options?.post_sign_in_location
          ? navTo.bind(null, nav.options?.post_sign_in_location, void 0, { use_modal: true })
          : undefined,
      });
    }
  }, [nav.options?.post_sign_in_location, navTo, signInState?.step, successSignIn]);

  function signInWithGoogle(evt?: Event) {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }

    authenticate({
      purpose: nav.options?.purpose,
      setError,
      setSignInState,
      groupToJoin: nav.options?.group_to_join,
    });
  }

  if (error || emailError) {
    return (
      <SignInError
        type={emailError ? 'email' : 'google'}
        reason={error || emailError}
        primaryActionCb={() => {
          navTo('/account/login', 'google-sign-in', {
            use_modal: true,
            login_step: LoginStep.INIT,
          });
        }}
        includeModal={true}
      />
    );
  }

  if (signInState && [LoginStep.SUCCESS, LoginStep.COMPLETING].includes(signInState.step)) {
    return (
      <SignInSuccess
        completingSignIn={signInState.step === LoginStep.COMPLETING}
        includeModal={true}
        isExistingUser={isExistingUser}
      />
    );
  }

  return (
    <ContainerInner
      id="rph-sign-in-with-google"
      customClass={classNames('rph-sign-in-with-google', {
        'rph-sign-in-with-google__email': shouldSuggestGoogleSignInBasedOnEmail,
        'rph-sign-in-with-google__without-one-tap': !oneTapEnabled,
      })}
    >
      {() => (
        <>
          {shouldSuggestGoogleSignInBasedOnEmail && (
            <>
              <h2 className="rph-sign-in-with-google__title"> {t('Sign in faster with Google')}</h2>
              <div className="rph-sign-in-with-google__subtitle">
                {t(
                  'This email "{{email}}" looks like a Google account. Would you like to sign in with Google instead?',
                  {
                    email: identifier,
                  },
                )}
              </div>
            </>
          )}
          {oneTapEnabled ? (
            <>
              <div id="rph-sign-in-with-google-prompt-parent" />
              {loadingOneTap && (
                <div className="rph-one-tap-loading rph-centered">
                  <span className="rph-loading-circle rph-loading-circle-large" />
                </div>
              )}
              <div className="rph-extra-content">
                <button className="rph-google-button" onClick={signInWithGoogle}>
                  <GoogleUser />
                  {t('Use another account')}
                </button>
              </div>
            </>
          ) : (
            <button
              type="button"
              className={`rph-signin-btn`}
              onClick={() => {
                if (isMobileApp) {
                  return sendMessageToApp({
                    type: MessageType.TRIGGER_SIGN_IN_WITH_GOOGLE,
                    payload: {
                      intent: nav.options?.intent,
                      ...(nav.options?.group_to_join && { group_to_join: nav.options?.group_to_join }),
                      hint: identifier,
                    },
                  });
                }
                authenticate({
                  purpose: nav.options?.purpose,
                  setError,
                  setSignInState,
                  intent: nav.options?.intent,
                  groupToJoin: nav.options?.group_to_join,
                  loginHint: identifier,
                });
              }}
            >
              <span className="rph-signin-btn__google" data-type="standard" />
              {t('Continue with Google')}
            </button>
          )}
          {shouldSuggestGoogleSignInBasedOnEmail && (
            <Button
              label="Continue with email sign in"
              type="tertiary-light"
              customClass="rph-sign-in-with-google__button"
              isLoading={isSubmittingEmail}
              handleOnClick={() => {
                initSignIn({
                  setSignInState,
                  userIdentifier: identifier as string,
                  loginType: 'email',
                  fieldError: null,
                  addlFieldInit: () => ({}),
                  setIsExistingUser,
                  addlFieldValues: {},
                  continueWithEmail: true,
                  setFieldError: (reason) => setError(reason),
                });
              }}
            />
          )}
        </>
      )}
    </ContainerInner>
  );
}
