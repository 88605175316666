import { logger } from '../utils/log';
import { EventType, INTERNAL_EVENT_PREFIX } from './types';
import { prepareEvent } from './prepare';

const bus = new EventTarget();

const events = {
  // eslint-disable-next-line no-undef
  addEventListener: (
    type: string,
    callback: EventListenerOrEventListenerObject | null,
    options?: boolean | AddEventListenerOptions | undefined,
  ): void => {
    if (!Object.values<string>(EventType).includes(type)) {
      console.warn(`Unknown event type: ${type}`);
      return;
    }

    bus.addEventListener(type, callback, options);
  },
  removeEventListener: (
    type: string,
    callback: EventListenerOrEventListenerObject | null,
    options?: EventListenerOptions | boolean,
  ) => {
    try {
      bus.removeEventListener(type, callback, options);
    } catch (err) {
      logger.error(err);
    }
  },
  dispatchEvent: (event: Event): boolean => {
    if (!Object.values<string>(EventType).includes(event.type)) {
      throw new Error(`Unknown event type: ${event.type}`);
    }

    return bus.dispatchEvent(event);
  },
  dispatch: (type: EventType, data: any = {}): boolean => {
    return bus.dispatchEvent(prepareEvent(type, data));
  }
};

// Built-in event handlers
events.addEventListener(EventType.AUTH, () => {
  document.querySelectorAll('[data-rph-login-btn]').forEach((el) => {
    if (!(el instanceof HTMLElement)) {
      return;
    }

    el.dataset.rowndUnauthenticatedText = el.textContent!;
    el.textContent = el.dataset.rphLoggedInText || el.dataset.rowndAuthenticatedText || el.innerText;
  });
});

// LOG OUT
events.addEventListener(EventType.SIGN_OUT, () => {
  document.querySelectorAll('[data-rph-login-btn]').forEach((el) => {
    if (!(el instanceof HTMLElement)) {
      return;
    }

    el.textContent = el.dataset.rowndUnauthenticatedText || el.textContent;
  });
});

export { events, EventType, INTERNAL_EVENT_PREFIX };
