import { h, JSX } from 'preact';
import { SignInMethods, useGlobalContext } from '@/scripts/hooks/use-global-context';
import classnames from 'classnames';
import { useTranslation } from 'preact-i18next';
import Button from '../../Common/Button/Button';
import { useMemo } from 'preact/hooks';
import ContainerInner from '@/scripts/ContainerInner';

type SignInErrorProps = {
  type: keyof SignInMethods | null;
  reason: string;
  code?: SignInErrorMessages;
  primaryActionCb: () => void;
  includeModal?: boolean;
};

export enum SignInErrorMessages {
  UnauthorizedEmail,
  SignUpDisabled,
  SignUpDisabledPhone,
}

export const useSignInErrorMessages = (): Record<SignInErrorMessages, string> => {
  const { t } = useTranslation();
  return useMemo<Record<SignInErrorMessages, string>>(
    () => ({
      [SignInErrorMessages.UnauthorizedEmail]: t('Please use an authorized email address.'),
      [SignInErrorMessages.SignUpDisabled]: t('Please sign up with an authorized email address.'),
      [SignInErrorMessages.SignUpDisabledPhone]: t('Please sign up with an authorized phone number.'),
    }),
    [t]
  );
};

export function SignInError(props: SignInErrorProps): JSX.Element {
  const { t } = useTranslation();
  const { state } = useGlobalContext();
  const { computed_color_mode } = state;
  const { type, reason, primaryActionCb } = props;

  const hideVerificationIcons = !!state.app?.config?.hub?.customizations?.hide_verification_icons;

  const isVerificationError = !type || ['email', 'phone'].includes(type);

  let errorTitle = t('Sign-in failed');

  const failureMessage =
    state.app?.config?.hub?.custom_content?.sign_in_failure_modal?.failure_message ??
    t('Having trouble? Reach out to support@rownd.io');

  switch (type) {
    case 'apple':
      errorTitle = t('Apple sign-in failed');
      break;
    case 'google':
      errorTitle = t('Google sign-in failed');
      break;
    case 'crypto_wallet':
      errorTitle = t('Wallet sign-in failed');
      break;
    case 'passkeys':
      errorTitle = t('Passkey sign-in failed');
      break;
  }

  const Content = useMemo(() => {
    return (
      <div className="rph-signin-error">
        {!isVerificationError && !hideVerificationIcons && (
          <div className="rph-signin-error-alt-image-container">
            <div
              className="rph-signin-error-alt-image-background"
              style={`background-color: ${computed_color_mode === 'dark' ? 'black' : '#9d9d9d'} !important`}
            />
            <div className="rph-signin-error-alt-image" />
          </div>
        )}
        <h2 className="rph-login-waiting__title">{errorTitle}</h2>
        <p className="rph-login-waiting__subtitle">
          {reason}
        </p>
        {isVerificationError && !hideVerificationIcons && (
          <div className="rph-login-waiting-image-container">
            <div
              className="rph-login-waiting-image-background"
              style={`background-color: ${computed_color_mode === 'dark' ? 'black' : '#9d9d9d'} !important`}
            />
            <div
              className={classnames('rph-login-waiting-image', {
                'rph-login-phone': type === 'phone',
              })}
            />
          </div>
        )}
        <div className="rph-login-waiting-footer">
          <Button
            customClass="rph-login-waiting__button-resend"
            label={t('Try again')}
            type="primary"
            handleOnClick={primaryActionCb}
          />
          <div className="rph-signin-error__message">{failureMessage}</div>
        </div>
      </div>
    );
  }, [computed_color_mode, errorTitle, hideVerificationIcons, isVerificationError, primaryActionCb, reason, t, type]);

  if (props?.includeModal) {
    return <ContainerInner>{() => <div className="rph-login">{Content}</div>}</ContainerInner>;
  }

  return Content;
}
