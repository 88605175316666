import { h } from 'preact';
import { useGlobalContext, type GlobalStateNav } from '@/scripts/hooks/use-global-context';
import NearWalletDetails from './Near/NearWalletDetails/NearWalletDetails';
import { WalletTypes } from '../Profile/ProfileWallets/ProfileWallets';

export default function WalletDetails() {
  const { state } = useGlobalContext();
  const nav = state.nav as GlobalStateNav<'/account/walletDetails'>;
  const { options } = nav;
  let walletDetails: WalletTypes = WalletTypes.Unknown;
  if (options?.type && Object.values(WalletTypes).includes(options?.type)) {
    walletDetails = options.type;
  }

  switch (walletDetails) {
    case WalletTypes.Near:
      return <NearWalletDetails />;
    case WalletTypes.Unknown:
    default:
      return <h1>Missing correct type for WalletDetails</h1>;
  }
}
