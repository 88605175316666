import { RequestSignInIntent } from '../ExternalApi';
import { logger } from './log';

export type RowndHubInteropMessage<T extends MessageType> = {
  type: T;
  payload?: MessagePayload<T>;
};

export enum MessageType {
  AUTHENTICATION = 'authentication',
  SIGN_IN = 'sign_in',
  SIGN_OUT = 'sign_out',
  CLOSE_HUB_VIEW_CONTROLLER = 'close_hub_view_controller',
  TRIGGER_SIGN_IN_WITH_APPLE = 'trigger_sign_in_with_apple',
  USER_DATA_UPDATE = 'user_data_update',
  TRIGGER_SIGN_IN_WITH_GOOGLE = 'trigger_sign_in_with_google',
  TRIGGER_SIGN_UP_WITH_PASSKEY = 'trigger_sign_up_with_passkey',
  TRIGGER_SIGN_IN_WITH_PASSKEY = 'trigger_sign_in_with_passkey',
  HUB_LOADED = 'hub_loaded',
  HUB_RESIZE = 'hub_resize',
  CAN_TOUCH_BACKGROUND_TO_DISMISS = 'can_touch_background_to_dismiss',
  EVENT = 'event',
  LOG_MESSAGE = 'log_message',
  AUTH_CHALLENGE_INITIATED = 'auth_challenge_initiated',
  AUTH_CHALLENGE_CLEARED = 'auth_challenge_cleared',
  OPEN_EMAIL_APP = 'open_email_app',
}

export type MessagePayload<T extends MessageType> = T extends MessageType.AUTHENTICATION
  ? AuthenticationMessage
  : T extends MessageType.USER_DATA_UPDATE
  ? UserDataUpdateMessage
  : T extends MessageType.SIGN_IN
  ? SignInMessage
  : T extends MessageType.SIGN_OUT
  ? SignOutMessage
  : T extends MessageType.TRIGGER_SIGN_IN_WITH_GOOGLE
  ? SignInMessage
  : T extends MessageType.HUB_RESIZE
  ? HubResizeMessage
  : T extends MessageType.CAN_TOUCH_BACKGROUND_TO_DISMISS
  ? CanTouchBackgroundToDismissMessage
  : T extends MessageType.EVENT
  ? EventMessage
  : T extends MessageType.LOG_MESSAGE
  ? LogMessage
  : T extends MessageType.AUTH_CHALLENGE_INITIATED
  ? AuthChallengeInitiatedMessage
  : Record<string, unknown>;

type SignInMessage = {
  intent?: RequestSignInIntent;
  group_to_join?: string;
  hint?: string;
};

type SignOutMessage = {
  was_user_initiated?: boolean;
}

type AuthenticationMessage = {
  access_token: string;
  refresh_token: string;
};

type UserDataUpdateMessage = {
  data: { [key: string]: any };
  meta: { [key: string]: any };
};

type HubResizeMessage = {
  height?: string;
};

type CanTouchBackgroundToDismissMessage = {
  enable?: 'true' | 'false';
};

type EventMessage = {
  event: string;
  data?: { [key: string]: any };
};

type AuthChallengeInitiatedMessage = {
  challenge_id: string;
  user_identifier: string;
};

type LogMessage = string;

export function sendMessageToApp<T extends MessageType>(message: RowndHubInteropMessage<T>, handlerId?: string) {
  try {
    const jsonMessage = JSON.stringify(message);

    let androidNativeHandler: keyof Window = 'rowndAndroidSDK';
    let iosNativeHandler: keyof Window['webkit']['messageHandlers'] = 'rowndIosSDK';
    switch (handlerId) {
      case 'logging': {
        androidNativeHandler = 'rowndDeviceLogger';
        iosNativeHandler = 'rowndDeviceLogger';
        break;
      }

      case 'sdk':
      default: {
        // no-op - use defaults
      }
    }

    if (window?.[androidNativeHandler]) {
      window[androidNativeHandler]?.postMessage(jsonMessage);
    }

    if (window?.webkit?.messageHandlers?.[iosNativeHandler]) {
      return window.webkit.messageHandlers[iosNativeHandler]?.postMessage(jsonMessage);
    }
  } catch (err: any) {
    // If we got here due to a logging message, this could cause an infinite loop
    if (handlerId === 'logging') {
      return;
    }

    logger.error(
      `Failed to send message to app: ${message.type} (handlerId: ${handlerId}, error: ${err.message})`,
      err,
    );
  }
}
