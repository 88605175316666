import preact, { h } from 'preact';
import { useGlobalContext, type GlobalStateNav } from '@/scripts/hooks/use-global-context';
import { useTranslation } from 'preact-i18next';
import { useCallback, useMemo, useState } from 'preact/hooks';
import Button from '../Common/Button/Button';
import useHandleClose from '@/scripts/hooks/use-handle-close';
import useUserApi from '@/scripts/hooks/use-user-api';
import { logger } from '@/scripts/utils/log';
import { parseISO, isBefore, isValid, format } from 'date-fns';

export type LegalDocs = {
  name: string;
  url: string;
  updated_at: string;
  schema_field: string;
};

export default function LegalOptIn() {
  const { t } = useTranslation();
  const { state } = useGlobalContext();
  const { setUser } = useUserApi();
  const handleClose = useHandleClose();
  const nav = state.nav as GlobalStateNav<'/account/legalOptIn'>;
  const [error, setError] = useState<string | undefined>();
  const [isSaving, setIsSaving] = useState(false);
  const { user } = state;

  const requiredDocs = useMemo(() => {
    const legalDocs: LegalDocs[] | undefined = nav.options?.legal_docs;

    if (!state?.app?.schema || !legalDocs) return undefined;

    return legalDocs.reduce((docList: LegalDocs[], doc) => {
      // Get last updated as a date
      const lastUpdated = parseISO(doc.updated_at);

      // Get user last accepted as a date
      const lastAccepted = parseISO(user.data?.[doc.schema_field]);

      if (!isValid(lastAccepted) || isBefore(lastAccepted, lastUpdated)) {
        docList.push(doc);
      }

      return docList;
    }, []);
  }, [nav.options?.legal_docs, state?.app?.schema, user.data]);

  const handleSubmit = useCallback(
    async (e: preact.JSX.TargetedMouseEvent<HTMLButtonElement> | undefined) => {
      e?.preventDefault();
      e?.stopPropagation();
      setIsSaving(true);

      // FIXME: We should probably have the server capture this timestamp
      // so that the user can't set a past date.
      const currentDate = new Date().toISOString();
      const userDataUpdates = requiredDocs?.reduce((updates: Record<string, string>, doc) => {
        updates[doc.schema_field] = currentDate;
        return updates;
      }, {});

      try {
        await setUser({
          ...state.user.data,
          ...userDataUpdates,
        });

        handleClose();
      } catch (err: any) {
        logger.error('Error saving user data', err);
        setError(t(`Failed to process your response: ${err.message}`));
      } finally {
        setIsSaving(false);
      }
    },
    [handleClose, requiredDocs, setUser, state.user.data, t],
  );

  return (
    <div className="rph-modal rph-legal-opt-in">
      <div className="rph-legal-opt-in__title">
        {nav.options?.title || t('Please agree to our terms & conditions')}
      </div>
      <div className="rph-legal-opt-in__subtitle">
        {nav.options?.subtitle || t('Read the following documents, then press "I agree" to continue.')}
        <ul className="rph-legal-opt-in__doc-list">
          {requiredDocs?.map((doc, index) => (
            <li key={`doc-${index}`}>
              <a href={doc.url} target="_blank" rel="noreferrer">
                {doc.name}
              </a>
              <span>(updated {format(parseISO(doc.updated_at), 'yyyy-MM-dd')})</span>
            </li>
          ))}
        </ul>
      </div>
      <Button label={t('I agree')} handleOnClick={handleSubmit} isLoading={isSaving} />
      {error && <div className="rph-input__error">{error}</div>}
    </div>
  );
}
