import { sessionContainer } from "../storage/session";
import { logger } from "../utils/log";
import { MessageType, sendMessageToApp } from "../utils/mobile-app";
import { FeatureFlagTypes, isFeatureEnabled } from "../utils/storage";
import { EventType } from "./types";

export function prepareEvent(type: EventType, data: any = {}): CustomEvent<any> {
    if (!Object.values<string>(EventType).includes(type)) {
        throw new Error(`Unknown event type: ${type}`);
    }

    const event = new CustomEvent(type, {
        detail: data,
    });

    logger.debug(`Dispatching event: ${type}:`, data);

    if (isFeatureEnabled({ storage: sessionContainer.storage, feature: FeatureFlagTypes.CanReceiveEventMessages })) {
        logger.debug('Sending event to app:', type);
        sendMessageToApp({
            type: MessageType.EVENT,
            payload: {
                event: type,
                data,
            },
        });
    }

    return event;
}
