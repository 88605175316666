export const INTERNAL_EVENT_PREFIX = 'internal';

export enum EventType {
  AUTH = 'auth',
  SIGN_OUT = 'sign_out',
  USER_DATA = 'user_data',
  STORAGE_READY = 'storage_ready',
  USER_DATA_SAVED = 'user_data_saved',
  POST_AUTHENTICATION_API_REQUEST_COMPLETE = 'post_authentication_api_request_complete',
  POST_AUTHENTICATION_STEPS_ERROR = 'post_authentication_steps_error',
  ROOT_SYNC = 'root_sync',
  GROUP_INVITE_ACCEPT_COMPLETE = 'group_invite_accept_complete',
  AUTOMATION_POST_SIGN_IN_COMPLETE = 'automation_post_sign_in_complete',
  AUTOMATION_TRIGGERED = 'automation_triggered',
  AUTOMATION_COMPLETED = 'automation_completed',
  SIGN_IN_STARTED = 'sign_in_started',
  SIGN_IN_COMPLETED = 'sign_in_completed',
  SIGN_IN_FAILED = 'sign_in_failed',
  VERIFICATION_STARTED = 'verification_started',
  VERIFICATION_COMPLETED = 'verification_completed',
  PROMPT_TO_SIGN_IN_WITH_GOOGLE_INSTEAD = 'prompt_to_sign_in_with_google_instead',

  // INTERNAL EVENT TYPES
  INTERNAL_SESSION_STORAGE_UPDATED = `internal_session_storage_updated`,
}

export type TAuthEvent = {
  access_token: string;
  user_id: string;
  app_id: string;
};
