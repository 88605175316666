import { ProfileTypes, profileTypesTranslation } from '@/scripts/Components/Profile/Profile';
import { PROFILE_TITLE_CLASS } from '../Profile';
import { useGlobalContext } from '@/scripts/hooks/use-global-context';
import { ROWND_LINKS } from '@/scripts/utils/links';
import useBottomSheet from '@/scripts/hooks/use-bottom-sheet';
import { useTranslation } from 'preact-i18next';
import classNames from 'classnames';
import Button from '../../Common/Button/Button';
import { useInstantUser } from '@/scripts/hooks/use-instant-user';
import { useMemo } from 'preact/hooks';
import { useRoute } from '@/scripts/hooks';

const Preferences = () => {
  const { state } = useGlobalContext();
  const { isBottomSheetEnabled } = useBottomSheet();
  const { t } = useTranslation();
  const { instantUserAccessToken } = useInstantUser();
  const { user, app, config } = state;
  const { navTo } = useRoute();

  const isMobileAppView = config?.displayContext === 'mobile_app';

  const preferencesEnabled = app.config?.hub?.profile?.preferences?.enabled !== false;
  const deleteAccountButtonEnabled = app.config?.hub?.profile?.delete_account_button?.enabled;

  const accountType = useMemo(() => (instantUserAccessToken ? 'Session ID' : 'Account ID'), [instantUserAccessToken]);

  const contactEmail = `mailto:${
    state.app.config?.hub?.legal?.support_email || ROWND_LINKS.SUPPORT_EMAIL
  }?subject=${encodeURIComponent(`Support (${app.name})`)}&body=${encodeURIComponent(
    `${accountType}: ${user.data.user_id}\nApp ID: ${app.id}\n\nPlease describe your issue below:`
  )}`;

  if (!state.use_modal || !preferencesEnabled) return null;

  return (
    <>
      <div className="rph-account-fields-form__divider" />
      {!isMobileAppView && !isBottomSheetEnabled && (
        <div className={`rph-account-fields-form__title ${PROFILE_TITLE_CLASS}${ProfileTypes.Preferences}`}>
          {instantUserAccessToken ? 'Support' : profileTypesTranslation.preferences.title}
        </div>
      )}
      {user.data.user_id && (
        <div
          className={classNames('rph-profile-preferences__account', {
            'rph-profile-preferences__account--mobile': isMobileAppView || isBottomSheetEnabled,
          })}
        >
          <div className="rph-profile-preferences__account__text">
            {accountType}: {user.data.user_id}
          </div>
          {isMobileAppView || isBottomSheetEnabled ? (
            <Button href={contactEmail} type="tertiary" label={t('Contact support')} />
          ) : (
            <a target="_blank" rel="noreferrer" href={contactEmail}>
              {t('Contact support')}
            </a>
          )}
        </div>
      )}
      {deleteAccountButtonEnabled && (
        <div
          className={classNames('rph-profile-preferences__delete', {
            'rph-profile-preferences__delete--mobile': isMobileAppView || isBottomSheetEnabled,
          })}
        >
          <div className="rph-profile-preferences__delete__text">{t('Delete account and all data')}</div>
          <Button
            type={isMobileAppView || isBottomSheetEnabled ? 'tertiary-danger' : 'text-danger'}
            handleOnClick={() => {
              navTo('/account/delete', 'DeleteAccount');
            }}
            label={t('Delete account')}
          />
        </div>
      )}
    </>
  );
};

export default Preferences;
