{
    "name": "rownd-privacy-hub",
    "version": "1.0.1",
    "private": true,
    "description": "Browser client for websites tying into Rownd",
    "type": "module",
    "engines": {
        "node": ">=16"
    },
    "main": "dist/index.mjs",
    "scripts": {
        "dev": "npm run start",
        "start": "npm run build && CF_TURNSTILE_CLIENT_KEY=0x4AAAAAAADYgBmm1_P3Y0tA concurrently npm:watch npm:serve npm:proxy",
        "build": "npm run clean && NODE_ENV=production node build.js",
        "build:nocleanup": "node build.js",
        "build:workerd": "NODE_ENV=production PLATFORM=workerd npm run build",
        "clean": "rimraf dist/",
        "watch": "npm-watch",
        "workerd": "workerd --verbose serve config.capnp",
        "serve": "wrangler pages dev --port 8787 dist --inspector-port 9339",
        "proxy": "http-proxy -p 8788 -P localhost:8787 -v",
        "test": "vitest --run",
        "test:coverage": "vitest --coverage --run",
        "types:check": "tsc --noEmit && tsc --noEmit -p test/tsconfig.json"
    },
    "watch": {
        "build": {
            "patterns": [
                "src",
                "public"
            ],
            "extensions": "*",
            "runOnChangeOnly": true
        }
    },
    "devDependencies": {
        "@cloudflare/kv-asset-handler": "^0.2.0",
        "@cloudflare/workers-types": "^4.20240222.0",
        "@ethersproject/providers": "^5.7.2",
        "@fingerprintjs/fingerprintjs": "^3.3.3",
        "@near-wallet-selector/core": "^8.0.3",
        "@near-wallet-selector/here-wallet": "^8.0.3",
        "@near-wallet-selector/meteor-wallet": "^8.1.1",
        "@near-wallet-selector/my-near-wallet": "^8.0.3",
        "@popeindustries/lit-html-server": "^3.1.0",
        "@simplewebauthn/browser": "^7.2.0",
        "@simplewebauthn/typescript-types": "^7.0.0",
        "@testing-library/preact": "^3.2.4",
        "@types/array.prototype.flatmap": "^1.2.2",
        "@types/big.js": "^6.2.2",
        "@types/bn.js": "^5.1.6",
        "@types/chai": "^4.3.10",
        "@types/debounce": "^1.2.1",
        "@types/dompurify": "^3.0.5",
        "@types/enzyme": "^3.10.16",
        "@types/http-errors": "^1.8.2",
        "@types/jsdom": "^21.1.6",
        "@types/lodash": "^4.14.178",
        "@types/lodash-es": "^4.17.6",
        "@types/mime": "^2.0.3",
        "@types/post-robot": "^10.0.3",
        "@types/sinon": "^17.0.3",
        "@types/ungap__structured-clone": "^0.3.0",
        "@typescript-eslint/eslint-plugin": "^5.52.0",
        "@typescript-eslint/parser": "^5.52.0",
        "@ungap/global-this": "^0.4.4",
        "@ungap/structured-clone": "^0.3.4",
        "@vitest/browser": "^3.0.0",
        "@vitest/coverage-v8": "^3.0.8",
        "array.prototype.flatmap": "^1.3.1",
        "autoprefixer": "^10.4.2",
        "buffer": "^6.0.3",
        "chalk": "^4.1.0",
        "classnames": "^2.3.1",
        "concurrently": "^7.0.0",
        "date-fns": "^2.28.0",
        "debounce": "^1.2.1",
        "dompurify": "^3.1.6",
        "enzyme": "^3.11.0",
        "enzyme-adapter-preact-pure": "^4.0.1",
        "esbuild": "^0.17.12",
        "esbuild-plugin-environment": "^0.2.4",
        "esbuild-plugin-polyfill-node": "^0.2.0",
        "esbuild-plugin-replace": "^1.4.0",
        "esbuild-plugin-resolve": "^2.0.0",
        "eslint": "^8.34.0",
        "eslint-config-preact": "^1.3.0",
        "eslint-config-prettier": "^8.6.0",
        "eslint-config-react-app": "^7.0.1",
        "eslint-plugin-prettier": "^4.2.1",
        "eslint-plugin-react-hooks": "^4.6.0",
        "estrella": "github:rownd/estrella",
        "fs-extra": "^11.1.0",
        "http-errors": "^2.0.0",
        "http-proxy-cli": "^2.1.0",
        "i18next": "^21.6.12",
        "i18next-browser-languagedetector": "^6.1.3",
        "i18next-http-backend": "^1.3.2",
        "install": "^0.13.0",
        "jsdom": "^24.0.0",
        "jwt-decode": "^3.1.2",
        "ky": "^0.33.2",
        "lodash-es": "^4.17.21",
        "lottie-web": "^5.10.0",
        "mime": "^4.0.3",
        "mocha": "^10.2.0",
        "npm-watch": "^0.13.0",
        "parse-duration": "^1.0.2",
        "phone": "^3.1.41",
        "playwright": "^1.51.0",
        "post-robot": "^10.0.46",
        "postcss": "^8.4.5",
        "pre-commit": "1.0",
        "preact": "^10.17.1",
        "preact-i18next": "^11.8.5",
        "prettier": "^2.8.4",
        "process": "^0.11.10",
        "qr-code-styling": "^1.6.0-rc.1",
        "raf": "^3.4.1",
        "rimraf": "^3.0.2",
        "sass": "^1.47.0",
        "sinon": "^17.0.1",
        "ts-node": "^10.9.2",
        "typescript": "^4.9.0",
        "vite": "^6.0.7",
        "vitest": "^3.0.8",
        "vitest-mock-extended": "^3.0.1",
        "wrangler": "^3.31.0"
    },
    "dependencies": {
        "@cloudflare/kv-asset-handler": "^0.2.0",
        "@ethersproject/providers": "^5.6.8",
        "@fingerprintjs/fingerprintjs": "^3.3.3",
        "@popeindustries/lit-html-server": "^3.1.0",
        "@types/chai": "^4.3.10",
        "array.prototype.flatmap": "^1.3.1",
        "debounce": "^1.2.1",
        "dompurify": "^3.1.6",
        "http-errors": "^2.0.0",
        "i18next": "^21.6.12",
        "i18next-browser-languagedetector": "^6.1.3",
        "i18next-http-backend": "^1.3.2",
        "lodash-es": "^4.17.21",
        "lottie-web": "^5.10.0",
        "phone": "^3.1.41",
        "post-robot": "^10.0.46",
        "preact": "^10.17.1",
        "preact-i18next": "^11.8.5",
        "sunder": "^0.10.1",
        "templater.js": "^3.0.1",
        "typescript": "^4.9.5",
        "workerd": "^1.20241106.1",
        "wrangler": "^3.31.0"
    },
    "pre-commit": [
        "build",
        "test"
    ]
}
