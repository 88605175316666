import { FunctionalComponent } from 'preact';
import useTickets from './useTickets';
import { useCallback, useEffect, useMemo } from 'preact/hooks';
import { ActionType, useGlobalContext } from '@/scripts/hooks/use-global-context';
import LoadingTicket from './LoadingTicket/LoadingTicket';
import NoTickets from './NoTickets/NoTickets';
import Button from '@/scripts/Components/Common/Button/Button';
import Event from './Event/Event';
import ErrorTicket from './ErrorTicket/ErrorTicket';
import useBottomSheet from '@/scripts/hooks/use-bottom-sheet';
import { useRoute } from '@/scripts/hooks';
import useWakeLock from '@/scripts/hooks/use-wake-lock';
import { useTranslation } from 'preact-i18next';

interface YourTicketsProps {
  onClose?: () => void;
}

const YourTickets: FunctionalComponent<YourTicketsProps> = ({ onClose }) => {
  useWakeLock(); // Keep screen awake
  const { state, dispatch } = useGlobalContext();
  const { isBottomSheetEnabled } = useBottomSheet();
  const { navTo } = useRoute();
  const { t } = useTranslation();
  const { refetchTickets, isLoading, error, events } = useTickets();

  useEffect(() => {
    if (!window.rownd.isAuthenticated()) {
      navTo('/account/login', 'your-tickets', {
        use_modal: true,
        post_sign_in_location: '/account/tickets',
      });
      return;
    }
  }, [state.auth.access_token, navTo]);

  const totalTickets = useMemo(() => {
    return events.reduce((sum, event) => sum + event.tickets.length, 0);
  }, [events]);

  const handleTryDifferentEmail = useCallback(() => {
    dispatch({
      type: ActionType.SIGN_OUT,
    });
    setTimeout(() => {
      navTo('/account/login', 'your-tickets', {
        use_modal: true,
        post_sign_in_location: '/account/tickets',
      });
    }, 0);
  }, [dispatch, navTo]);

  const uiState: 'loading' | 'empty' | 'error' | 'tickets' = useMemo(() => {
    if (isLoading) return 'loading';
    if (error) return 'error';

    return totalTickets > 0 ? 'tickets' : 'empty';
  }, [error, isLoading, totalTickets]);

  return (
    <div className="rph-your-tickets rph-modal">
      <div className="rph-your-tickets__title">Your tickets {uiState === 'loading' ? '' : `(${totalTickets})`}</div>
      {uiState !== 'loading' ? (
        <div className="rph-your-tickets__subtitle">
          {t('View your tickets for {{email}} below. Not seeing your tickets?', {
            email: state.user.data.email || 'your email',
          })}
          <Button type="text" handleOnClick={handleTryDifferentEmail}>
            {t('Try a different email')}
          </Button>
        </div>
      ) : (
        <div className="rph-your-tickets__subtitle-loading" />
      )}
      {uiState === 'loading' && <LoadingTicket />}
      {uiState === 'error' && <ErrorTicket refetchTickets={refetchTickets} error={error as string} />}
      {uiState === 'empty' && <NoTickets handleTryDifferentEmail={handleTryDifferentEmail} />}
      {uiState === 'tickets' && (
        <div className="rph-your-tickets__events">
          {events.map((event, index) => (
            <Event key={event.id} event={event} index={index} />
          ))}
          {isBottomSheetEnabled && (
            <>
              <div className="rph-your-tickets__events__not-seeing-tickets">
                {t('Not seeing your tickets?')}
                <Button type="text" handleOnClick={handleTryDifferentEmail}>
                  {t('Try a different email')}
                </Button>
              </div>
              <div className="rph-your-tickets__footer">
                <div className="rph-your-tickets__footer__text">{t('Powered by Rownd and SquadUp')}</div>
              </div>
            </>
          )}
        </div>
      )}
      {!isBottomSheetEnabled && (
        <div className="rph-your-tickets__footer">
          <div className="rph-your-tickets__footer__text">{t('Powered by Rownd and SquadUp')}</div>
          <Button type="tertiary" handleOnClick={onClose}>
            {t('Close')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default YourTickets;
