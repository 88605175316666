import { LoginStep } from '@/scripts/Login';
import classNames from 'classnames';
import { h, createContext } from 'preact';
import { useCallback, useEffect, useMemo, useRef, useState } from 'preact/hooks';
import { useGlobalContext, type GlobalStateNav } from '../../hooks/use-global-context';
import { BOTTOM_SHEET } from './BottomSheet.constants';
import useHandleClose from '@/scripts/hooks/use-handle-close';
import useAutomations from '@/scripts/Automations/hooks';
import type { RouteOptions } from '@/scripts/hooks/use-route';

export interface BottomSheetProps {
  children: (props: { closeModal: () => void }) => any;
  customClass?: string;
  id?: string;
}

export const BottomSheetContext = createContext<{ reset:() => void } | undefined>(undefined);

export default function BottomSheet({ children, customClass, id }: BottomSheetProps) {
  const { state } = useGlobalContext();
  const { nextAutomation, invokeAutomation } = useAutomations();
  const handleClose = useHandleClose();

  const [startTranslateY, setStartTranslateY] = useState(0);
  const bottomSheetRef = useRef<HTMLDivElement | null>(null);
  const bottomSheetFooterRef = useRef<HTMLDivElement | null>(null);
  const bottomSheetBackgroundRef = useRef<HTMLDivElement | null>(null);
  const scrollOffset = useRef(0);
  const startY = useRef(0);

  const isContainerDisabled = useMemo(() => {
    return state.nav.current_route === '/account/tickets';
  }, [state.nav.current_route]);

  const preventClosing = useMemo(
    () => state.nav.options?.prevent_closing === true,
    [state.nav.options?.prevent_closing],
  );

  const addStyleElement = useCallback(() => {
    const style = document.createElement('style');
    style.type = 'text/css';
    style.id = BOTTOM_SHEET.STYLE_ID;
    style.innerHTML = BOTTOM_SHEET.STYLE_CSS;
    document.head.appendChild(style);
  }, []);

  const removeStyleElement = useCallback(() => {
    const styleElement = document.getElementById(BOTTOM_SHEET.STYLE_ID);
    styleElement?.parentNode?.removeChild(styleElement);
  }, []);

  const swipeUpModal = useCallback(() => {
    if (!bottomSheetRef.current || !bottomSheetFooterRef.current || !bottomSheetBackgroundRef.current) return;
    bottomSheetRef.current.style.transition = `transform ${BOTTOM_SHEET.TRANSFORM_DURATION}ms ease-in-out`;
    bottomSheetRef.current.style.transform = `translateY(0px)`;
    bottomSheetFooterRef.current.style.transition = `transform ${BOTTOM_SHEET.TRANSFORM_DURATION}ms ease-in-out`;
    bottomSheetFooterRef.current.style.transform = `translateY(0px)`;

    bottomSheetBackgroundRef.current.style.backgroundColor = 'rgba(0, 0, 0, 0.4)';

    addStyleElement();
  }, [addStyleElement]);

  const swipeDownModal = useCallback(() => {
    if (!bottomSheetRef.current || !bottomSheetFooterRef.current || !bottomSheetBackgroundRef.current) return;
    bottomSheetRef.current.style.transition = `transform ${BOTTOM_SHEET.TRANSFORM_DURATION}ms ease-in-out`;
    bottomSheetRef.current.style.transform = `translateY(100%)`;
    bottomSheetFooterRef.current.style.transition = `transform ${BOTTOM_SHEET.TRANSFORM_DURATION}ms ease-in-out`;
    bottomSheetFooterRef.current.style.transform = `translateY(100%)`;

    bottomSheetBackgroundRef.current.style.backgroundColor = 'rgba(0, 0, 0, 0)';

    removeStyleElement();
  }, [removeStyleElement]);

  // Trigger slide up/down when modal open/closes
  useEffect(() => {
    if (!bottomSheetRef.current || !bottomSheetFooterRef.current || !bottomSheetBackgroundRef.current) return;
    if (state.use_modal) {
      swipeUpModal();
    } else {
      swipeDownModal();
    }

    return () => {
      // remove style element when component gets unmounted
      removeStyleElement();
    };
  }, [state.use_modal, swipeDownModal, swipeUpModal, removeStyleElement]);

  // Custom closeModal for bottom sheet
  const closeModal = useCallback(() => {
    if (nextAutomation) {
      invokeAutomation({ automation: nextAutomation });
      return;
    }

    swipeDownModal();

    setTimeout(() => {
      handleClose();
    }, BOTTOM_SHEET.TRANSFORM_DURATION);
  }, [handleClose, invokeAutomation, nextAutomation, swipeDownModal]);

  const handleTouchStart = useCallback(
    (e: h.JSX.TargetedTouchEvent<HTMLDivElement>, element?: 'header') => {
      if (element !== 'header' && isContainerDisabled) {
        return;
      }
      scrollOffset.current = 0;
      startY.current = e.touches[0].clientY || 0;
      setStartTranslateY(Number(bottomSheetRef.current?.style?.transform.match(/-?\d+(\.\d+)?/g)?.[0] || 0));
    },
    [isContainerDisabled],
  );

  const handleTouchMove = useCallback(
    (e: h.JSX.TargetedTouchEvent<HTMLDivElement>, element?: 'header') => {
      if (element !== 'header' && isContainerDisabled) {
        return;
      }
      if (!startY.current || !bottomSheetRef.current || !bottomSheetFooterRef.current) return;

      const currentY = e.touches[0].clientY;
      let distance = currentY - startY?.current + startTranslateY;

      const visualViewportHeight = window.visualViewport?.height || window.innerHeight;
      const bottomSheetHeight = bottomSheetRef.current.offsetHeight;

      const isScrollable = visualViewportHeight - bottomSheetHeight <= BOTTOM_SHEET.MAX_SCROLLABLE_HEIGHT;

      if (!isScrollable) {
        if (distance * -1 + bottomSheetHeight > visualViewportHeight - BOTTOM_SHEET.MAX_NO_SCROLLABLE_HEIGHT) {
          distance =
            bottomSheetHeight -
            visualViewportHeight +
            BOTTOM_SHEET.MAX_NO_SCROLLABLE_HEIGHT -
            Math.sqrt(
              (visualViewportHeight - BOTTOM_SHEET.MAX_NO_SCROLLABLE_HEIGHT - bottomSheetHeight + distance + 1) * -1,
            );
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (distance < 0 || Math.max(bottomSheetRef.current.scrollTop, 0) !== 0) {
          scrollOffset.current = distance;
          distance = 0;
          bottomSheetRef.current.style.overflowY = 'scroll';
        } else {
          distance = distance - scrollOffset.current;
          bottomSheetRef.current.style.overflowY = 'hidden';
        }
      }

      bottomSheetRef.current.style.removeProperty('transition');
      bottomSheetRef.current.style.transform = `translateY(${distance}px)`;
      bottomSheetFooterRef.current.style.removeProperty('transition');
      bottomSheetFooterRef.current.style.transform = `translateY(${distance}px)`;
    },
    [startTranslateY, isContainerDisabled],
  );

  const handleTouchEnd = useCallback(
    (e: h.JSX.TargetedTouchEvent<HTMLDivElement>, element?: 'header') => {
      if (element !== 'header' && isContainerDisabled) {
        return;
      }
      startY.current = 0;
      if (!bottomSheetRef.current || !bottomSheetFooterRef.current) return;

      const translateY = Number(bottomSheetRef.current?.style?.transform?.match(/-?\d+(\.\d+)?/g)?.[0] || 0);
      const bottomSheetHeight = bottomSheetRef.current.offsetHeight;
      const visualViewportHeight = window.visualViewport?.height || window.innerHeight;

      // Define snap points
      const snapPoints: Record<string, number> = {
        middle: 0,
        open: (visualViewportHeight - bottomSheetHeight) * 0.8 * -1,
        closed: bottomSheetHeight,
      };

      // Calculate the closest snap point
      const closestSnapPoint = Object.keys(snapPoints).reduce((prev, curr) =>
        Math.abs(snapPoints[curr] - translateY) < Math.abs(snapPoints[prev] - translateY) ? curr : prev,
      );

      const isCloseSnapPointe = closestSnapPoint === 'closed';
      if (isCloseSnapPointe && !preventClosing) {
        closeModal();
        return;
      }

      const snapPointTransform = isCloseSnapPointe ? snapPoints.middle : snapPoints[closestSnapPoint];

      bottomSheetRef.current.style.transition = `transform ${BOTTOM_SHEET.TRANSFORM_DURATION}ms ease-in-out`;
      bottomSheetRef.current.style.transform = `translateY(${snapPointTransform}px)`;
      bottomSheetFooterRef.current.style.transition = `transform ${BOTTOM_SHEET.TRANSFORM_DURATION}ms ease-in-out`;
      bottomSheetFooterRef.current.style.transform = `translateY(${snapPointTransform}px)`;
    },
    [closeModal, preventClosing, isContainerDisabled],
  );

  const shimmyModal = useCallback(() => {
    const isSIFWG =
      !!(state.nav.options as RouteOptions['/google'])?.suggest_google_sign_in_based_on_email &&
      state.nav.current_route === '/google';
    if (state.nav.step !== LoginStep.VERIFY && state.nav.step !== LoginStep.WAITING && !preventClosing && !isSIFWG) {
      closeModal();
      return;
    }

    if (!bottomSheetRef.current || !bottomSheetFooterRef.current || !bottomSheetBackgroundRef.current) return;
    const shimmyDuration = 100;
    bottomSheetRef.current.style.transform = `translateY(3%)`;
    bottomSheetRef.current.style.transition = `transform ${shimmyDuration}ms ease-in-out`;
    setTimeout(() => {
      if (!bottomSheetRef.current) return;
      bottomSheetRef.current.style.transform = `translateY(0%)`;
      bottomSheetRef.current.style.transition = `transform ${BOTTOM_SHEET.TRANSFORM_DURATION}ms ease-in-out`;
    }, shimmyDuration);
  }, [closeModal, preventClosing, state.nav.current_route, state.nav.options, state.nav.step]);

  const reset = useCallback(() => {
    if (!bottomSheetRef.current) return;
    bottomSheetRef.current.scrollTo({ top: 0 });
    bottomSheetRef.current.style.transform = `translateY(0px)`;
  }, []);

  return (
    <BottomSheetContext.Provider value={{ reset }}>
      <div
        className="rph-bottom-sheet-background"
        ref={bottomSheetBackgroundRef}
        onClick={() => shimmyModal()}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      />
      <div
        className={classNames(`rph-container-inner`, {
          [`${customClass}`]: customClass,
        })}
        id={id}
        ref={bottomSheetRef}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div
          onTouchStart={(e) => handleTouchStart(e, 'header')}
          onTouchEnd={(e) => handleTouchEnd(e, 'header')}
          onTouchMove={(e) => handleTouchMove(e, 'header')}
          className="rph-bottom-sheet-header"
        >
          <div className="rph-bottom-sheet-header__handle" />
        </div>
        {children({ closeModal })}
      </div>
      <div
        ref={bottomSheetFooterRef}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        className="rph-bottom-sheet-footer"
      />
    </BottomSheetContext.Provider>
  );
}
