import preact, { FunctionComponent } from 'preact';
import { SquadUpEventData, SquadUpTicketData } from '../../useTickets';
import TicketSVG from '@/scripts/assets/svg/TicketSVG';
import QrSVG from '@/scripts/assets/svg/QrSVG';
import Button from '@/scripts/Components/Common/Button/Button';
import { StateUpdater, useCallback, useEffect, useMemo, useState } from 'preact/hooks';
import { generateQrCode } from '@/scripts/QrCodeGenerator/generator';
import { useGlobalContext } from '@/scripts/hooks/use-global-context';
import useBottomSheet from '@/scripts/hooks/use-bottom-sheet';
import { useTranslation } from 'preact-i18next';
import useElementsWidth from '@/scripts/hooks/use-elements-width';
import { getUserAgentType } from '@/scripts/utils';
import { UserAgentType } from '@/scripts/utils';

interface TicketProps {
  ticket: SquadUpTicketData;
  event: SquadUpEventData;
  index: number;
  showQRCodeForEveryTicket: boolean;
  setShowQRCodeForEveryTicket: StateUpdater<boolean>;
}

const Ticket: FunctionComponent<TicketProps> = ({
  ticket,
  event,
  index,
  showQRCodeForEveryTicket,
  setShowQRCodeForEveryTicket,
}) => {
  const { width, ref } = useElementsWidth();
  const { state } = useGlobalContext();
  const { t } = useTranslation();
  const isDarkMode = state.computed_color_mode === 'dark';
  const { isBottomSheetEnabled } = useBottomSheet();
  const [qrCode, setQrCode] = useState<{ state: 'loading' | 'loaded' | 'error'; data?: string | Blob }>({
    state: 'loading',
    data: undefined,
  });
  const [showQRCode, setShowQRCode] = useState(false);

  const month = useMemo(() => {
    const date = new Date(event.start_at);
    return date.toLocaleString('default', { month: 'short' }).toUpperCase();
  }, [event.start_at]);

  const day = useMemo(() => {
    const date = new Date(event.start_at);
    return date.getDate().toString();
  }, [event.start_at]);

  const eventTime = useMemo(() => {
    const startDate = new Date(event.start_at);
    const endDate = new Date(event.end_at);

    const date = `${startDate.toLocaleString('default', { month: 'short', day: 'numeric', year: 'numeric' })}`;

    const time = `${startDate
      .toLocaleString('default', { hour: 'numeric', minute: 'numeric' })
      .split(' ')
      .join('')} - ${endDate.toLocaleString('default', { hour: 'numeric', minute: 'numeric' }).split(' ').join('')}`;

    const timezoneString = startDate.toLocaleString('default', { timeZoneName: 'short' });
    const timezone = timezoneString.slice(timezoneString.length - 3, timezoneString.length);

    return `${date} ${time} ${timezone}`;
  }, [event.start_at, event.end_at]);

  const isTicketAvailable = useMemo(() => {
    if (!isBottomSheetEnabled) {
      return Boolean(ticket.pdf_url);
    }

    return Boolean(ticket.qrcode_str);
  }, [isBottomSheetEnabled, ticket.pdf_url, ticket.qrcode_str]);

  useEffect(() => {
    (async () => {
      if (!ticket.qrcode_str) {
        return setQrCode({ state: 'loaded', data: undefined });
      }
      try {
        const qrCode = await generateQrCode(ticket.qrcode_str, {
          format: 'svg',
          encoding: 'base64',
          color_mode: state.computed_color_mode,
        });
        setQrCode({ state: 'loaded', data: qrCode });
      } catch (e) {
        setQrCode({ state: 'error', data: undefined });
      }
    })();
  }, [ticket.qrcode_str, state.computed_color_mode]);

  const ticketIndex = useMemo(() => {
    if (isBottomSheetEnabled) {
      return `${index + 1} of ${event.tickets.length}`;
    }
    return `${index + 1}`;
  }, [isBottomSheetEnabled, index, event.tickets.length]);

  const isMobile = useMemo(() => getUserAgentType() === UserAgentType.mobile, []);

  const shouldShowQrCode = useMemo(() => {
    if (isMobile) {
      return showQRCodeForEveryTicket;
    }
    return showQRCode;
  }, [isMobile, showQRCodeForEveryTicket, showQRCode]);

  const buttonText = useMemo(() => {
    return shouldShowQrCode ? t('Hide QR Code') : t('Show QR Code');
  }, [shouldShowQrCode, t]);

  const handleButtonClick = useCallback(() => {
    setShowQRCode((prev) => !prev);
    if (isMobile) {
      setShowQRCodeForEveryTicket((prev) => !prev);
    }
  }, [setShowQRCode, setShowQRCodeForEveryTicket, isMobile]);

  const imageUrl = useMemo(() => {
    const image = event.image;
    return image.thumbnail_url || image.default_url || '';
  }, [event.image]);

  return (
    <div className="rph-ticket">
      <div className="rph-ticket__header">
        <div className="rph-ticket__header__index">{ticketIndex}</div>
        <TicketSVG />
      </div>
      <div className="rph-ticket__body">
        <div className="rph-ticket__body__container">
          <div className="rph-ticket__body__image">
            {imageUrl ? (
              <img src={imageUrl} />
            ) : (
              <div className="rph-ticket__body__date">
                <div className="rph-ticket__body__date__month">{month}</div>
                <div className="rph-ticket__body__date__day">{day}</div>
              </div>
            )}
          </div>
          <div className="rph-ticket__body__content">
            <div className="rph-ticket__content__title">{event.name}</div>
            <div className="rph-ticket__content__date">{eventTime}</div>
            <div className="rph-ticket__content__type">{ticket.type}</div>
            <div className="rph-ticket__content__address">
              {event.location.name}, {event.location.address_line_1}
            </div>
          </div>
        </div>
        <div className="rph-ticket__footer" ref={ref}>
          {shouldShowQrCode && qrCode.data ? (
            <img className="rph-ticket__footer__qr-code" src={`data:image/svg+xml;base64,${qrCode.data}`} />
          ) : null}
          <Button
            disabled={!isTicketAvailable}
            type={shouldShowQrCode ? 'text' : 'primary'}
            handleOnClick={handleButtonClick}
          >
            {buttonText}
            <QrSVG />
          </Button>
          {!isTicketAvailable && (
            <div style={`max-width: ${width || 80}px !important`} className="rph-ticket__footer__not-available">
              {t('Ticket will appear 2 hours before showtime')}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Ticket;
