import classNames from 'classnames';
import { h } from 'preact';
import useBottomSheet from './hooks/use-bottom-sheet';
import { lazyWithRetry } from './utils/lazy-with-retry';
import { Suspense } from 'preact/compat';

const BottomSheet = lazyWithRetry(() => import('./Components/BottomSheet/BottomSheet'));

interface ContainerInnerProps {
  children: (props: { closeModal?: () => void }) => h.JSX.Element;
  customClass?: string;
  id?: string;
}

export default function ContainerInner({ children, customClass, id }: ContainerInnerProps) {
  const { isBottomSheetEnabled } = useBottomSheet();

  if (isBottomSheetEnabled) {
    return (
      <Suspense fallback={null}>
        <BottomSheet customClass={customClass} id={id}>
          {({ closeModal }) => {
            return children({ closeModal });
          }}
        </BottomSheet>
      </Suspense>
    );
  }

  return (
    <div
      className={classNames(`rph-container-inner`, {
        [`${customClass}`]: customClass,
      })}
      id={id}
    >
      {children({})}
    </div>
  );
}
