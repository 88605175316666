import preact, { h, Fragment } from 'preact';
import _pickBy from 'lodash/pickBy';
import { useMemo, useState } from 'preact/hooks';
import { BUTTON_SIGN_IN_METHODS, SignInMethods, useGlobalContext } from '@/scripts/hooks/use-global-context';
import { LoginStep } from '@/scripts/Login';
import { useTranslation } from 'preact-i18next';
import { LoginButtonsProps } from './LoginButtons.types';
import { RequestSignInIntent } from '@/scripts/ExternalApi';
import useSignInMethods from '@/scripts/hooks/use-sign-in-methods';

const connectAccountMethods: (keyof SignInMethods)[] = [
  'google',
  'apple',
  'crypto_wallet',
  'oauth2_meta-horizon',
];

export default function LoginButtons({
  passkeySignIn,
  appleIdSignIn,
  googleSignIn,
  anonymousSignIn,
  oauth2SignIn,
  setSignInState,
  setPrevSteps,
  initialStep,
  prevSteps,
  isSubmittingWeb3,
  verifyWalletBtnState,
  showAllMethods,
  selectedMethod,
  setSelectedMethod,
  setShowAllMethods,
  intent,
  purpose,
}: LoginButtonsProps) {
  const { t } = useTranslation();
  const { signInMethodsEnabled } = useSignInMethods();
  const { state } = useGlobalContext();
  const { app } = state;

  const order = app?.config?.hub?.auth?.order;
  const orderType = window?.webkit?.messageHandlers?.rowndIosSDK
    ? 'ios'
    : window?.rowndAndroidSDK
    ? 'android'
    : 'default';
  const orderArray = order?.[orderType] || order?.default || undefined;

  const ButtonWithLoading = ({ onClick, children }: { onClick: (evt: Event) => void; children: any }): preact.JSX.Element => {
    const [isLoading, setIsLoading] = useState(false);

    return (
      <button
        type="button"
        className="rph-signin-btn"
        onClick={(evt: Event) => {
          if (isLoading) return;

          setIsLoading(true);
          setTimeout(() => {
            setIsLoading(false);
          }, 3000);

          onClick(evt);
        }}
        onTouchStart={() => undefined}
        disabled={isLoading}
      >
        {isLoading ? <span className="rph-loading-circle" /> : children}
      </button>
    );
  };

  const dynamicAuthButtons = useMemo((): preact.JSX.Element[] => {
    const buttonOptions: Record<keyof SignInMethods, preact.JSX.Element | undefined> = {
      passkeys: signInMethodsEnabled.passkeys ? (
        <ButtonWithLoading
          onClick={(evt: Event) => passkeySignIn({ evt, opts: { purpose: 'authentication' } })}
        >
          {' '}
          <span className="rph-signin-btn-mask rph-signin-btn__passkey" />
          {t('Continue with a passkey')}
        </ButtonWithLoading>
      ) : undefined,
      apple: signInMethodsEnabled.apple ? (
        <ButtonWithLoading
          onClick={(evt: Event) => appleIdSignIn({ evt, opts: { purpose } })}
        >
          {' '}
          <span className="rph-signin-btn__appleid" />
          {t('Continue with Apple')}
        </ButtonWithLoading>
      ) : undefined,
      google: signInMethodsEnabled.google ? (
        <>
          <ButtonWithLoading
            onClick={(evt: Event) => googleSignIn({ evt, opts: { purpose } })}
          >
            {' '}
            <span className="rph-signin-btn__google" data-type="standard" />
            {t('Continue with Google')}
          </ButtonWithLoading>
          <div id="rph-sign-in-with-google-probe-parent" style="display: none !important;" />
        </>
      ) : undefined,
      anonymous: signInMethodsEnabled.anonymous ? (
        <>
          <ButtonWithLoading
            onClick={(evt: Event) => anonymousSignIn({ evt })}
          >
            {' '}
            <span className="rph-signin-btn-mask rph-signin-btn__anonymous" data-type="standard" />
            {t('Continue as a guest')}
          </ButtonWithLoading>
        </>
      ) : undefined,
      crypto_wallet: signInMethodsEnabled.crypto_wallet ? (
        <button
          disabled={verifyWalletBtnState}
          type="button"
          className="rph-signin-btn"
          onClick={() => {
            setPrevSteps([...prevSteps, initialStep]);
            setSignInState({ step: LoginStep.CHOOSE_WALLET_PROVIDER });
          }}
          onTouchStart={() => undefined}
        >
          <span className="rph-signin-btn-mask rph-signin-btn__wallet" />
          {isSubmittingWeb3 ? t('Waiting on provider...') : t('Continue with a wallet')}
        </button>
      ) : undefined,
      email:
        showAllMethods && signInMethodsEnabled.email ? (
          <>
            <ButtonWithLoading
              onClick={() => {
                setSelectedMethod('email');
                setShowAllMethods(false);
              }}
            >
              {' '}
              <span className="rph-signin-btn-mask rph-signin-btn__email" data-type="standard" />
              {t('Continue with email')}
            </ButtonWithLoading>
            <div id="rph-sign-in-with-google-probe-parent" style="display: none !important;" />
          </>
        ) : undefined,
      phone:
        showAllMethods && signInMethodsEnabled.phone ? (
          <>
            <ButtonWithLoading
              onClick={() => {
                setSelectedMethod('phone');
                setShowAllMethods(false);
              }}
            >
              {' '}
              <span className="rph-signin-btn-mask rph-signin-btn__phone" data-type="standard" />
              {t('Continue with Phone')}
            </ButtonWithLoading>
            <div id="rph-sign-in-with-google-probe-parent" style="display: none !important;" />
          </>
        ) : undefined,
    };

    if (signInMethodsEnabled.oauth2) {
      const oauth2Methods = Object.entries(state.app.config?.hub?.auth?.sign_in_methods || {})
        .filter(([method, cfg]) => method.startsWith('oauth2') && cfg.enabled) as [string, SignInMethods['oauth2']][];

      oauth2Methods.forEach(([method, methodCfg]) => {
        buttonOptions[method as keyof SignInMethods] = (
          <Fragment key={method}>
            <ButtonWithLoading
              onClick={(evt: Event) => oauth2SignIn({ evt, opts: { method, purpose } })}
            >
              {' '}
              {(!!methodCfg.icon_light_url || !!methodCfg.icon_dark_url) && (
                <style scoped>
                  {`
                    #rownd-privacy-hub .rph-signin-btn__${method} {
                      mask: unset !important;
                      background-color: unset !important;
                      background-image: url(${methodCfg.icon_light_url}) !important;
                    }

                    #rownd-privacy-hub .rph-colorscheme-dark .rph-signin-btn__${method} {
                      background-image: url(${methodCfg.icon_dark_url}) !important;
                    }

                    @media (prefers-color-scheme: dark) {
                      #rownd-privacy-hub .rph-colorscheme-auto .rph-signin-btn__${method} {
                        background-image: url(${methodCfg.icon_dark_url}) !important;
                      }
                    }
                    `}
                </style>
              )}
              <span className={`rph-signin-btn__oauth2 rph-signin-btn__${method}`} data-type="standard" />
              {t(`Continue with ${methodCfg.display_name}`)}
            </ButtonWithLoading>
          </Fragment>
        );
      });
    }

    const buttons = _pickBy(buttonOptions, (value, key) => {
      if (purpose === 'connect_account') {
        return connectAccountMethods.includes(key as keyof SignInMethods) && app?.config?.hub?.auth?.sign_in_methods?.[key as keyof SignInMethods]?.enabled;
      }

      return true;
    });

    if (orderArray) {
      return orderArray.flatMap((method) => {
        return showAllMethods ||
          intent === RequestSignInIntent.SignIn ||
          (!method?.hidden && !selectedMethod) ||
          selectedMethod === method.name
          ? buttons?.[method.name] || []
          : [];
      });
    }

    const defaultButtons = BUTTON_SIGN_IN_METHODS;

    // If in Android webView put Google as first auth method
    if (window?.rowndAndroidSDK) {
      defaultButtons.splice(defaultButtons.indexOf('google'), 1);
      defaultButtons.unshift('google');
    }

    return defaultButtons.flatMap((type): preact.JSX.Element | preact.JSX.Element[] => {
      if (type === 'oauth2') {
        return Object.keys(buttons)
          .filter((method) => method.startsWith('oauth2_'))
          .map((method) => buttonOptions?.[method as keyof SignInMethods])
          .filter((button) => !!button) as preact.JSX.Element[];
      }

      if (selectedMethod && selectedMethod !== type) return [];
      return buttons?.[type] || [];
    });
  }, [
    signInMethodsEnabled.passkeys,
    signInMethodsEnabled.apple,
    signInMethodsEnabled.google,
    signInMethodsEnabled.anonymous,
    signInMethodsEnabled.crypto_wallet,
    signInMethodsEnabled.email,
    signInMethodsEnabled.phone,
    signInMethodsEnabled.oauth2,
    t,
    verifyWalletBtnState,
    isSubmittingWeb3,
    showAllMethods,
    state.app.config?.hub?.auth?.sign_in_methods,
    orderArray,
    passkeySignIn,
    appleIdSignIn,
    googleSignIn,
    anonymousSignIn,
    setPrevSteps,
    prevSteps,
    initialStep,
    setSignInState,
    setSelectedMethod,
    setShowAllMethods,
    oauth2SignIn,
    intent,
    selectedMethod,
    purpose,
  ]);

  return <div className="rph-additional-sign-in-methods">{dynamicAuthButtons.map((method) => method)}</div>;
}
