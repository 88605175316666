import React from 'preact';

function WarningSVG() {
  return (
    <svg id="warning-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <defs>
        <style>{`.cls-1{fill:none;}`}</style>
      </defs>
      <title>warning</title>
      <path
        d="M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm0,26A12,12,0,1,1,28,16,12,12,0,0,1,16,28Z"
        transform="translate(0 0)"
        fill="currentColor"
      />
      <rect fill="currentColor" x="15" y="8" width="2" height="11" />
      <path fill="currentColor" d="M16,22a1.5,1.5,0,1,0,1.5,1.5A1.5,1.5,0,0,0,16,22Z" transform="translate(0 0)" />
      <rect
        id="_Transparent_Rectangle_"
        data-name="&lt;Transparent Rectangle&gt;"
        class="cls-1"
        width="32"
        height="32"
      />
    </svg>
  );
}

export default WarningSVG;
