import { h } from 'preact';
import { useGlobalContext, type GlobalStateNav } from '@/scripts/hooks/use-global-context';
import { Suspense } from 'preact/compat';
import { lazyWithRetry } from '@/scripts/utils/lazy-with-retry';

const ConnectPasskey = lazyWithRetry(() => import('./ConnectPasskey/ConnectPasskey'));

export enum ConnectAuthenticatorTypes {
  Passkey = 'passkey',
  Unknown = 'unknown',
}

export default function ConnectAuthenticator() {
  const { state } = useGlobalContext();
  const nav = state.nav as GlobalStateNav<'/account/connectAuthenticator'>;
  let connectAuthenticator: ConnectAuthenticatorTypes = ConnectAuthenticatorTypes.Unknown;
  if (nav.options?.type && Object.values(ConnectAuthenticatorTypes).includes(nav.options.type)) {
    connectAuthenticator = nav.options.type;
  }

  const ConnectAuthenticatorComponent = () => ({
    [ConnectAuthenticatorTypes.Passkey]: (
      <Suspense fallback={null}>
        <ConnectPasskey />
      </Suspense>
    ),
    [ConnectAuthenticatorTypes.Unknown]: <h1>Missing correct type for ConnectAuthenticator</h1>,
  });

  return ConnectAuthenticatorComponent()[connectAuthenticator];
}
