import ContainerInner from '@/scripts/ContainerInner';
import OidcConfirmAccount from '@/scripts/Components/Oidc/OidcConfirmAccount/OidcConfirmAccount';
import OidcConsent from '@/scripts/Components/Oidc/OidcConsent/OidcConsent';
import { useGlobalContext } from '@/scripts/hooks/use-global-context';
import { useCallback, useEffect, useState } from 'preact/hooks';
import { logger } from '@/scripts/utils/log';
import useOidc from '@/scripts/hooks/use-oidc';
import useSafeLocation from './hooks/use-window-location';
import { useRoute } from '@/scripts/hooks';
import OidcLoading from './Components/Oidc/OidcLoading/OidcLoading';

export default function Oidc() {
  const { state } = useGlobalContext();
  const oidc = useOidc();
  const safeLocation = useSafeLocation();
  const { navTo } = useRoute();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // If the user is not signed in, prompt them to sign in.
    if (!window.rownd.isAuthenticated()) {
      if (!oidc.config?.login_endpoint) {
        logger.error('No login_uri found in oidc options');
        return;
      }
      window._rphConfig.push(['setPostSignInCallback', callLoginApi.bind(null, oidc.config?.login_endpoint, true)]);
      navTo('/account/login', 'oidc', {
        use_modal: true,
        prevent_closing: true,
        include_user_data: true,
        redirect: false,
      });
    }
  }, [oidc.config?.login_endpoint, state.auth.access_token, navTo]);

  useEffect(() => {
    if (loading) return;

    if (
      oidc.config?.login_endpoint &&
      oidc.config?.interaction?.prompt?.name === 'login' &&
      window.rownd.isAuthenticated() &&
      state.is_post_sign_in_requirements_done
    ) {
      try {
        setLoading(true);
        callLoginApi(oidc.config?.login_endpoint);
      } catch (err) {
        logger.error('Error calling login api', err);
      } finally {
        setLoading(false);
      }
    }
  }, [oidc.config, state.auth.access_token, state.is_post_sign_in_requirements_done, loading]);

  useEffect(() => {
    const missingRequiredConfigItems = [];
    if (!oidc.config?.confirm_endpoint) {
      missingRequiredConfigItems.push('confirm_endpoint');
    } else if (!oidc.config?.confirm_account_endpoint) {
      missingRequiredConfigItems.push('confirm_account_endpoint');
    }
    if (missingRequiredConfigItems.length > 0) {
      setError(`Missing required oidc config items: ${missingRequiredConfigItems.join(', ')}`);
    } else {
      setError(null);
    }
  }, [oidc.config?.confirm_account_endpoint, oidc.config?.confirm_endpoint]);

  const returnToOrigianlUrl = useCallback(() => {
    try {
      const state = atob((oidc.config?.interaction?.params?.state || '..').split('.')[1]);
      const stateObj = JSON.parse(state);
      if (stateObj.return_to) {
        safeLocation.replace(stateObj.redirect_uri);
      } else {
        logger.error('Could not determine original url to return to');
      }
    } catch (err) {
      logger.error('Error returning to original url', err);
    }
  }, [oidc.config?.interaction?.params?.state, safeLocation]);

  return (
    <ContainerInner customClass="rph-oidc-container-inner">
      {() => {
        return (
          <div className="rph-oidc rph-modal">
            {loading && !oidc.config?.interaction?.prompt?.name &&<OidcLoading />}
            {error && <p className="rph-oidc__error">{error}</p>}
            {oidc.config?.interaction?.prompt?.name === 'confirm_account' && (
              <OidcConfirmAccount handleCancel={window.rownd.signOut} />
            )}
            {oidc.config?.interaction?.prompt?.name === 'consent' && <OidcConsent handleCancel={returnToOrigianlUrl} />}
          </div>
        );
      }}
    </ContainerInner>
  );
}

async function callLoginApi(loginUri: string, accountConfirmed = false) {
  const accessToken = await window.rownd.getAccessToken({ waitForToken: true });
  const form = document.createElement('form');
  form.method = 'POST';
  form.action = loginUri;

  const accessTokenInput = document.createElement('input');
  accessTokenInput.type = 'hidden';
  accessTokenInput.name = 'access_token';
  accessTokenInput.value = accessToken as string;

  const accountConfirmedInput = document.createElement('input');
  accountConfirmedInput.type = 'hidden';
  accountConfirmedInput.name = 'account_confirmed';
  accountConfirmedInput.value = accountConfirmed.toString();

  form.appendChild(accessTokenInput);
  form.appendChild(accountConfirmedInput);
  document.body.appendChild(form);
  form.submit();
}
