import { useCallback, useRef } from 'preact/hooks';
import * as webauthn from '@simplewebauthn/browser';
import {
  AuthenticationResponseJSON,
  PublicKeyCredentialCreationOptionsJSON,
  PublicKeyCredentialRequestOptionsJSON,
} from '@simplewebauthn/typescript-types';
import { logger } from '../utils/log';
import { ActionType, useGlobalContext } from './use-global-context';
import useApi from './use-api';
import useRoute from './use-route';
import { ConnectAuthenticatorTypes } from '../Components/ConnectAuthenticator/ConnectAuthenticator';
import type { UserType } from '../DefaultContext';
import { events, EventType } from '../events';
import { SignInMethodTypes } from './use-sign-in';

export type UserPasskey = {
  id: string;
  user_agent?: string;
};

export type UserPasskeysResp = {
  passkeys: UserPasskey[];
};

export type EagerChallenge = {
  optionsJson: PublicKeyCredentialRequestOptionsJSON | PublicKeyCredentialCreationOptionsJSON | null;
  request: Promise<PublicKeyCredentialRequestOptionsJSON | PublicKeyCredentialCreationOptionsJSON> | null;
};

export type PasskeyAuthenticationCompleteResp = {
  verified: boolean;
  user_type: UserType;
  app_variant_user_type?: UserType;
  access_token: string;
  refresh_token: string;
};

export default function usePasskeys() {
  const { state, dispatch } = useGlobalContext();
  const { client: api } = useApi();
  const { navTo: nav } = useRoute();

  // This is used as a holder for challenge data that is pre-fetched prior to registration or authentication.
  // Since Safari requires that a webauthn create/get be tied closely to a user input gesture, it's important
  // to avoid API calls during the user flow.
  // NOTE: It is *very* important to set `optionsJson = null` after consuming the challenge data so that it
  // is not re-used by accident.
  const eagerChallenge = useRef<EagerChallenge>({
    optionsJson: null,
    request: null,
  });

  const eagerlyRequestRegistrationChallenge = useCallback(() => {
    eagerChallenge.current.request = new Promise((resolve, reject) => {
      api
        .get('hub/auth/passkeys/registration', { authenticated: true })
        .then<PublicKeyCredentialCreationOptionsJSON>((resp) => resp.json())
        .then((body: PublicKeyCredentialCreationOptionsJSON) => {
          eagerChallenge.current.optionsJson = body;
          resolve(body);
        })
        .catch(reject)
        .finally(() => (eagerChallenge.current.request = null));
    });
  }, [api]);

  const eagerlyRequestAuthenticationChallenge = useCallback(() => {
    eagerChallenge.current.request = new Promise((resolve, reject) => {
      api
        .get('hub/auth/passkeys/authentication', {
          headers: {
            'x-rownd-app-key': state.config?.appKey,
          },
        })
        .then<PublicKeyCredentialRequestOptionsJSON>((resp) => resp.json())
        .then((body: PublicKeyCredentialRequestOptionsJSON) => {
          eagerChallenge.current.optionsJson = body;
          resolve(body);
        })
        .catch(reject)
        .finally(() => (eagerChallenge.current.request = null));
    });
  }, [api, state.config?.appKey]);

  const shouldPromptForPasskeyRegistration = useCallback((): boolean => {
    if (!state.app?.config?.hub?.auth?.sign_in_methods?.passkeys?.enabled) {
      return false;
    }

    return state.user.passkeys?.registrations?.length === 0;
  }, [state.app?.config?.hub?.auth?.sign_in_methods?.passkeys?.enabled, state.user.passkeys?.registrations?.length]);

  const promptForPasskeyRegistration = useCallback(async () => {
    if (!state.auth.access_token || !state.app.id || !state.user.meta) {
      logger.log('state is not ready for passkey registration');
      return;
    }

    // Prompt for passkey registration
    nav('/account/connectAuthenticator', 'state', {
      type: ConnectAuthenticatorTypes.Passkey,
      use_modal: true,
    });
  }, [nav, state.app.id, state.auth.access_token, state.user.meta]);

  const promptForPasskeyRegistrationIfNeeded = useCallback(async () => {
    if (!shouldPromptForPasskeyRegistration()) {
      logger.log('should prompt for passkey is false');
      return;
    }

    promptForPasskeyRegistration();
  }, [shouldPromptForPasskeyRegistration, promptForPasskeyRegistration]);

  const fetchPasskeyRegistrations = useCallback(
    async (refresh = false) => {
      if (
        !state.auth.access_token ||
        (state.user?.passkeys?.registrations?.length && !refresh) ||
        !state.app.config?.hub?.auth?.sign_in_methods?.passkeys?.enabled
      ) {
        return;
      }

      const passkeysResp: UserPasskeysResp = await api
        .get(`me/auth/passkeys`, {
          authenticated: true,
        })
        .json();

      dispatch({
        type: ActionType.SET_USER_PASSKEYS,
        payload: passkeysResp.passkeys,
      });
    },
    [
      api,
      dispatch,
      state.app.config?.hub?.auth?.sign_in_methods?.passkeys?.enabled,
      state.auth.access_token,
      state.user?.passkeys?.registrations?.length,
    ],
  );

  const register = useCallback(async () => {
    if (
      !eagerChallenge.current.optionsJson ||
      !(eagerChallenge.current.optionsJson as PublicKeyCredentialCreationOptionsJSON)?.user
    ) {
      eagerlyRequestRegistrationChallenge();
      await eagerChallenge.current.request;
    }

    const passKeyRegResult = await webauthn.startRegistration(
      eagerChallenge.current.optionsJson as PublicKeyCredentialCreationOptionsJSON,
    );
    eagerChallenge.current.optionsJson = null;
    logger.debug('Passkey registration result:', passKeyRegResult);

    const verifyResp = await api.post('hub/auth/passkeys/registration', {
      authenticated: true,
      json: passKeyRegResult,
    });
    const verifyObj = await verifyResp.json();
    logger.debug('Passkey registration verification result:', verifyObj);

    dispatch({
      type: ActionType.SET_SIGN_IN_METHOD,
      payload: { last_sign_in: 'passkeys', enable_priority: true },
    });

    return verifyObj;
  }, [api, dispatch, eagerlyRequestRegistrationChallenge]);

  const startAuthentication = useCallback(
    async (attemptToUseAutofill = false) => {
      if (
        !eagerChallenge.current.optionsJson ||
        (eagerChallenge.current.optionsJson as PublicKeyCredentialCreationOptionsJSON)?.user
      ) {
        eagerlyRequestAuthenticationChallenge();
        await eagerChallenge.current.request;
      }

      logger.debug('Passkey authentication init resp:', eagerChallenge.current.optionsJson);

      try {
        const passKeyAuthResult = await webauthn.startAuthentication(
          eagerChallenge.current.optionsJson as PublicKeyCredentialRequestOptionsJSON,
          attemptToUseAutofill,
        );
        logger.debug('Passkey authentication device result:', passKeyAuthResult);
        eagerChallenge.current.optionsJson = null;
        return passKeyAuthResult;
      } catch (err) {
        eagerlyRequestAuthenticationChallenge();
        if (typeof err === 'string') {
          throw new Error(err);
        }

        throw err;
      }
    },
    [eagerlyRequestAuthenticationChallenge],
  );

  const authenticate = useCallback(
    async ({
      result,
      groupToJoin,
    }: {
      result?: AuthenticationResponseJSON;
      groupToJoin?: string;
    } = {}): Promise<PasskeyAuthenticationCompleteResp> => {
      const passKeyAuthResult = result || (await startAuthentication());

      const authenticateResp = await api.post('hub/auth/passkeys/authentication', {
        headers: {
          'x-rownd-app-key': state.config?.appKey,
        },
        json: {
          ...passKeyAuthResult,
          groupToJoin,
        },
      });

      const authenticateRespResult = await authenticateResp.json<PasskeyAuthenticationCompleteResp>();
      logger.debug('Passkey authentication server result:', authenticateRespResult);

      if (!authenticateResp.ok) {
        logger.error('Passkey authentication failed:', authenticateRespResult);
        throw new Error('Passkey authentication failed.');
      }

      dispatch({
        type: ActionType.SET_SIGN_IN_METHOD,
        payload: { last_sign_in: 'passkeys' },
      });

      dispatch({
        type: ActionType.LOGIN_SUCCESS,
        payload: authenticateRespResult,
      });

      events.dispatch(EventType.SIGN_IN_COMPLETED, {
        method: SignInMethodTypes.PASSKEYS,
        user_type: authenticateRespResult.user_type,
        app_variant_user_type: authenticateRespResult.app_variant_user_type,
      });

      return authenticateRespResult;
    },
    [api, dispatch, startAuthentication, state.config?.appKey],
  );

  return {
    register,
    authenticate,
    startAuthentication,
    browserSupportsWebAuthn: webauthn.browserSupportsWebAuthn,
    promptForPasskeyRegistrationIfNeeded,
    promptForPasskeyRegistration,
    eagerlyRequestRegistrationChallenge,
    eagerlyRequestAuthenticationChallenge,
    fetchPasskeyRegistrations,
  };
}
