import { useEffect, useState } from 'preact/hooks';
import { events, EventType } from '../events';
import { sessionContainer } from '../storage/session';
import { logger } from '../utils/log';

export function updateStorage() {
  // localStorage is included to help debug
  const sessionStorage = { ...window?.sessionStorage, ...window?.localStorage };
  const filteredSessionStorage: Record<string, string> = {};

  Object.keys(sessionStorage).forEach((key) => {
    if (key.startsWith('rph_')) {
      filteredSessionStorage[key] = sessionStorage[key];
    }
  });

  sessionContainer.storage = filteredSessionStorage;

  events.dispatch(EventType.INTERNAL_SESSION_STORAGE_UPDATED, { storage: filteredSessionStorage });
};

logger.debug('Initializing session storage');
updateStorage();
window.addEventListener('storage', () => {
  logger.debug('Updating session storage due to storage event');
  updateStorage();
});

// This hook is a shim to push any storage updates to components without running excess cycles
const useSessionStorage = () => {
  const [storage, setStorage] = useState<Record<string, string>>(sessionContainer.storage);

  useEffect(() => {
    const updateHookStorage = () => {
      setStorage(sessionContainer.storage);
    };

    events.addEventListener(EventType.INTERNAL_SESSION_STORAGE_UPDATED, updateHookStorage);
    return () => {
      events.removeEventListener(EventType.INTERNAL_SESSION_STORAGE_UPDATED, updateHookStorage);
    };
  }, []);

  return storage;
};

export default useSessionStorage;
